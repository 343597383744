const middleware = {}

middleware['account/emailConfirm'] = require('../middleware/account/emailConfirm.ts')
middleware['account/emailConfirm'] = middleware['account/emailConfirm'].default || middleware['account/emailConfirm']

middleware['account/emailReset'] = require('../middleware/account/emailReset.ts')
middleware['account/emailReset'] = middleware['account/emailReset'].default || middleware['account/emailReset']

middleware['account/passwordReset'] = require('../middleware/account/passwordReset.ts')
middleware['account/passwordReset'] = middleware['account/passwordReset'].default || middleware['account/passwordReset']

middleware['auth'] = require('../middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['explorer/localisationMap'] = require('../middleware/explorer/localisationMap.ts')
middleware['explorer/localisationMap'] = middleware['explorer/localisationMap'].default || middleware['explorer/localisationMap']

middleware['noauth'] = require('../middleware/noauth.ts')
middleware['noauth'] = middleware['noauth'].default || middleware['noauth']

middleware['profile/activities'] = require('../middleware/profile/activities.ts')
middleware['profile/activities'] = middleware['profile/activities'].default || middleware['profile/activities']

middleware['profile/settings'] = require('../middleware/profile/settings.ts')
middleware['profile/settings'] = middleware['profile/settings'].default || middleware['profile/settings']

middleware['redirections'] = require('../middleware/redirections.ts')
middleware['redirections'] = middleware['redirections'].default || middleware['redirections']

middleware['website/contact'] = require('../middleware/website/contact.ts')
middleware['website/contact'] = middleware['website/contact'].default || middleware['website/contact']

middleware['website/homepage'] = require('../middleware/website/homepage.ts')
middleware['website/homepage'] = middleware['website/homepage'].default || middleware['website/homepage']

export default middleware

import { GetterTree, ActionTree, MutationTree } from 'vuex';

// =================================== STATE ===================================

export const state = () => ({
  globalSearchModalIsDisplayed: false,
  sitePartners: [],
  showNewsletterModal : false,
  isMobileDownloadBannerDisplayed : false,
});

export type RootState = ReturnType<typeof state>

// ================================== GETTERS ==================================

export const getters: GetterTree<RootState, RootState> = {
};

// ================================= MUTATIONS =================================

export const mutations: MutationTree<RootState> = {
  SET_GLOBALSEARCH_DISPLAY (state, payload: boolean) {
    state.globalSearchModalIsDisplayed = payload;
  },
  SET_SITE_PARTNERS (state, payload) {
    state.sitePartners = payload;
  },
  SET_NEWSLETTER_SHOW (state, payload: boolean) {
    state.showNewsletterModal = payload;
  },
  SET_MOBILE_DOWNLOAD_BANNER_DISPLAY (state, payload: boolean) {
    state.isMobileDownloadBannerDisplayed = payload;
  },
};

// ================================== ACTIONS ==================================

export const actions: ActionTree<RootState, RootState> = {
  async uploadFile (ctx, file) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const formData = new FormData();
    formData.append('file', file);
    const { data } = await this.$axios.post(`/upload`, formData, { headers });

    return data.url;
  },

  async getSitePartners (ctx) {
    const { data } = await this.$axios.get(`/public/partners/logos`);

    ctx.commit('SET_SITE_PARTNERS', data);
    return data;
  },
};

export const isObject = value => Object.prototype.toString.call(value) === '[object Object]';
export const isBoolean = value => typeof value === 'boolean';
export const isDate = value => Object.prototype.toString.call(value) === '[object Date]';
export const isFile = value => Object.prototype.toString.call(value) === '[object File]';
export const isId = value => Number.isInteger(value) && value > 0;

const sizeLabels = ['o', 'ko', 'Mo', 'Go'];

export function objectIsEmpty (object) {
  return object &&
      Object.keys(object).length === 0 &&
      object.constructor === Object;
}

export function octets (size) {
  let i = 0;
  while (Math.abs(size) >= 1024 && i < sizeLabels.length - 1) {
    size /= 1024;
    i++;
  }
  return `${size.toFixed(i > 1 ? 2 : 0)}${sizeLabels[i]}`.replace('.', ',');
}

/** @throws {Error} if guard(value) is not true */
export function throwIfNot (guard, value) {
  if (guard(value) !== true) {
    var prettyValue;
    try {
      prettyValue = JSON.stringify(value);
    } catch (e) {
      console.warn("[Warn] Couldn't serialize value because of the following error:", e);
    }
    const msg = process.env.NODE_ENV === 'production'
      ? 'A precondition has failed and the action could not be completed'
      : `${guard.name}(${prettyValue}: ${typeof value}) has failed`;
    throw new Error(msg);
  }
}

/** @returns {String} name of value's type constructor */
export function getTypeName (value) {
  const typeString = Object.prototype.toString.call(value);
  // "[jsBaseType actualType]" => "actualType" e.g. "[object Object]" => "Object"
  return typeString.split(' ')[1].slice(0, -1);
}

export function apiQueryBuilder (payload) {
  let finalQuery = "?";
  for (const key in payload) {
    if (Array.isArray(payload[key])) {
      for (let i = 0; i < payload[key].length; i++) {
        finalQuery += `${key}[]=${payload[key][i]}&`;
      }
    } else if (payload[key]) {
      finalQuery += `${key}=${payload[key]}&`;
    }
  }
  return finalQuery.slice(0, -1);
}

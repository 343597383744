

























import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component
export default class Dropdown extends Vue {
  @Prop(String) containerClasses: '';
  @Prop(String) contentClasses: '';
  @Prop({ type: String, required: true }) openType: string;
  @Prop(Boolean) takesFullWidth: false;

  dropdownIsActive: boolean = false;

  /* Handle the position of the dropdown to be opened based on prop */
  get openDropdownPosition () {
    if (this.openType === 'left') return 'left-0';
    if (this.openType === 'right') return 'right-0';
    return '';
  }

  /*
    Utils method to handle dropdown behavior if user clicks inside.
    We emit in case we have logic or styles to apply parent-side. We will use
    this.dropdownIsActive in this case to handle styles
  */
  @Emit('open-dropdown')
  openDropdown () {
    this.dropdownIsActive = !this.dropdownIsActive;
    return this.dropdownIsActive;
  }

  /*
    Closes active dropdown. We emit in case we have logic or styles to
    apply parent-side.
  */
  @Emit('close-dropdown')
  bluredBtn () {
    this.dropdownIsActive = false;
  }
}

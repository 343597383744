






















import { Vue, Component } from 'vue-property-decorator';
import { mapState } from 'vuex';

import TopMenu from '~/components/menus/TopMenu.vue';
import ProfileBanner from '~/components/lists/ProfileBanner.vue';
import GlobalSearch from '~/components/modals/GlobalSearch.vue';

@Component({
  components: { TopMenu, ProfileBanner, GlobalSearch },
  middleware: 'auth',
  computed: {
    ...mapState([
      'globalSearchModalIsDisplayed',
    ]),
  },
})
export default class SignInLayout extends Vue {

}

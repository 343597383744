






















import { Vue, Component } from 'vue-property-decorator';

import { directive } from 'vue-awesome-swiper';

import 'swiper/css/swiper.css';

import ArrowNarrowRightSolid from '~/components/icons/ArrowNarrowRightSolid.vue';
import ChevronRightSolid from '~/components/icons/ChevronRightSolid.vue';
@Component({
  components: { ArrowNarrowRightSolid, ChevronRightSolid },
  directives: {
    swiper: directive,
  },
})
export default class GlobalSwiper extends Vue {
  swiperOption = {
    direction: 'horizontal',
    loop: false,

    breakpoints: {
      320: {
        slidesPerView: 1,
        // spaceBetween: 20,
      },

      414: {
        slidesPerView: 2,
        // spaceBetween: 20,
      },

      768: {
        slidesPerView: 3,
        // spaceBetween: 20,
      },

      1024: {
        slidesPerView: 4,
        // spaceBetween: 32,
      },
      1536: {
        slidesPerView: 4,
        // spaceBetween: 30,
      },
    },

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  }

  swiperIsReady: boolean = false;
};

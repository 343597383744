
export function tokenError (error) {
  // NOTE: If messages must be customized in the future, simply stop using
  // this function (API error message) and handle separately each of the 3
  // status codes in each of the token errors below.
  switch (error.response?.status) {
    case 401:
    case 404:
    case 409:
    case 410:
      return error.response.data.detail;
  }
}

export function activationError (error) {
  return {
    statusCode: error.response?.status,
    title:'Activation link error …',
    description: tokenError(error) || 'This link does not match any ongoing account activation',
  };
}

export function validationError (error) {
  return {
    statusCode: error.response?.status,
    title:'Validation error …',
    description: error,
  };
}

export function inviteError (error) {
  return {
    statusCode: error.response?.status,
    title:'Invite link error …',
    description: tokenError(error) || 'This link does not match any pending invite',
  };
}

export function emailResetError (error) {
  return {
    statusCode: error.response?.status,
    title:'Email reset link error …',
    description: tokenError(error) || 'This link does not match any email reset attempt',
  };
}

export function passwordResetError (error) {
  return {
    statusCode: error.response?.status,
    title:'Password reset link error …',
    description: tokenError(error) || 'This link does not match any password reset attempt',
  };
}
























import { Vue, Component } from 'vue-property-decorator';
import { mapState } from 'vuex';

import TopMenu from '~/components/menus/TopMenu.vue';

@Component({
  components: { TopMenu },
  computed: {
    ...mapState('user', [
      'signLayoutImage',
    ]),
  },
})
export default class SignInLayout extends Vue {

}

























import { Vue, Component, Prop } from 'vue-property-decorator';

import LocationMarker from '~/components/icons/LocationMarker.vue';

@Component({
  components: { LocationMarker },
})
export default class GlobalSearchFilteredLocalisation extends Vue {
  @Prop(Object) localisation: Types.ReverseLocalisation;
}

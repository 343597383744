import type { Context, Plugin } from "@nuxt/types";
import type { Inject } from "@nuxt/types/app";

import { signOutAppleID } from "./apple";
import { signOutFacebookSDK } from "./facebook";
import { signOutGapi } from "./google";

const SIGNIN_PROVIDER_KEY = "onpiste-signin-provider";

const socialProviders = new Set([
  "apple",
  "facebook",
  "google",
]);

export interface Config {
  APPLE_APP_ID: string;
  FACEBOOK_APP_ID: string;
  GOOGLE_APP_ID: string;
}

export class SocialPlugin {
  conf: Config;

  constructor (conf: Config) {
    this.conf = conf;
  }

  rememberProvider (providerName: Types.SocialProvider) {
    if (process.client) {
      window.$nuxt.$cookies.set(SIGNIN_PROVIDER_KEY, providerName);
    }
  }

  getCurrentProvider () {
    const p: Types.SocialProvider | null = process.client ? window.$nuxt.$cookies.get(SIGNIN_PROVIDER_KEY) : null;
    // const isValid = p === null || socialProviders.has(p);
    // if (!isValid) {
    //   throw new Error(`Unexpected value for cookie.${SIGNIN_PROVIDER_KEY}`);
    // }
    return p;
  }

  /**
   * If the user has signed in with a social network, this function signs them out
   * of our app "on the social network".
   * i.e. next time the user tries to sign in with this social network, they will
   * have to provide credentials again.
   * This DOES NOT sign the user out of the social network itself.
   */
  signOutIfSignedIn () {
    const provider = this.getCurrentProvider();

    switch (provider) {
      case "apple": signOutAppleID(this.conf.APPLE_APP_ID); break;
      case "facebook": signOutFacebookSDK(this.conf.FACEBOOK_APP_ID); break;
      case "google": signOutGapi(this.conf.GOOGLE_APP_ID); break;
    }

    if (process.client) window.$nuxt.$cookies.remove(SIGNIN_PROVIDER_KEY);
  }
}

const socialPlugin: any = (nuxtCtx: any, inject: Inject) => {
  const social = new SocialPlugin(nuxtCtx.$config);

  inject("social", social);
};

export default socialPlugin;

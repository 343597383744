import { injectScript } from '~/utils/dom';

// The authorization must be stored because apple won't give it more than once!
// XXX: This could be added in the store to allow signing out from provider (on our website)
var appleAuth: Types.AppleResponse | undefined;
var appleIDIsLoaded: boolean = false;
var loading: Promise<void>;

const SCRIPT_ID = "appleid-script";
const SCRIPT_SRC = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";

export const ignoredAppleErrors = new Set([
  "user_trigger_new_signin_flow",
  "popup_closed_by_user",
  "user_cancelled_authorize",
]);

export function loadAppleID (clientId: string): Promise<void> {
  if (appleIDIsLoaded) {
    return Promise.resolve();
  }
  if (!loading) {
    // Prepare the AppleID loader that will be executed when script is ready
    loading = new Promise((resolve, reject) => {
      const el = injectScript(SCRIPT_ID, SCRIPT_SRC);
      el.onload = () => {
        window.AppleID.auth.init({
          clientId,
          redirectURI: `${window.location.origin}/signup/email`,
          scope: "name email",
          usePopup: true,
        });
        resolve();
      };
    });
  }
  return loading;
}

export async function getAppleAuth (): Promise<Types.AppleResponse> {
  if (!appleAuth) {
    appleAuth = await window.AppleID.auth.signIn();
  }
  return appleAuth;
}

export function signOutAppleID (clientId: string) {
  // It is not possible to sign out with apple.
  // We can only delete any loaded data. This will force the getAppleAuth
  // function above to sign in again next time.
  appleAuth = undefined;
}

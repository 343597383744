import { Middleware } from '@nuxt/types';

const activitiesMiddleware: Middleware = async ({ store, app }) => {
  console.info('[activitiesMiddleware]');
  store.commit('SET_GLOBALSEARCH_DISPLAY', false);

  if (!store.state.explorer.sports.length) {
    await store.dispatch('explorer/getSports', { lang: store.state.lang.locale });
  }

  if (!store.state.activities.activities.length) {
    const storeDate = store.state.activities.filteredDate;

    const start = storeDate ? app.$dayjs(store.state.activities.filteredDate?.startDate).hour(0).minute(0).second(0).millisecond(0) : undefined;
    const end = storeDate ? app.$dayjs(store.state.activities.filteredDate?.endDate).hour(23).minute(59).second(59).millisecond(999) : undefined;

    const sports = store.state.activities.filteredSports.length > 0 ? store.state.activities.filteredSports?.map((f: Types.SportItem) => f.id) : undefined;

    await store.dispatch('activities/getActivities', {
      offset: 0,
      limit: 12,
      start_after: start,
      start_before: end,
      sports,
    });
  }
};

export default activitiesMiddleware;

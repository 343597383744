var slugify = require('slugify');

export default ({ store }, inject) => {
  inject('slugify', (string) => {
    const locale = store.state.lang.locale;
    string = string.replace("/", "-");
    return slugify(string, {
      locale: locale === 'ca' ? 'en' : locale,
      lower: true,
      remove: /[*+~,.()'"!:;@?#`]/g,
    });
  });
};











import { Vue, Component } from 'vue-property-decorator';
import { mapState } from 'vuex';

import TopMenu from '~/components/menus/TopMenu.vue';
import Footer from '~/components/partials/Footer.vue';
import GlobalSearch from '~/components/modals/GlobalSearch.vue';

@Component({
  components: { TopMenu, Footer, GlobalSearch },
  computed: {
    ...mapState([
      'globalSearchModalIsDisplayed',
    ]),
  },
})
export default class DefaultLayout extends Vue {

}

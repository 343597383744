import { paramSerializer } from '~/utils/url-serializer.js';

export default function ({ store, $axios, req }) {
  $axios.defaults.paramsSerializer = paramSerializer;
  $axios.onError((error) => {
    const { url, method, baseURL } = error.config;

    // add more logs than nuxt quite short ones
    console.log(`${method.toUpperCase()} ${baseURL}${url}`);
    console.log(error);
  });

  // uncomment when needing to get requests URLs
  // $axios.onRequest((config) => {
  //   const { url, method, baseURL } = config;
  //
  //   // add more logs than nuxt quite short ones
  //   console.log(`${method.toUpperCase()} ${baseURL}${url}`);
  // });

  store.dispatch('user/loadToken');
}

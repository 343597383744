import { ActionTree, MutationTree } from 'vuex';

// =================================== STATE ===================================

export const state: Types.WebsiteState = () => ({
  content: null,
  homeContent: null,
});

export type RootState = ReturnType<typeof state>

// ================================= MUTATIONS =================================

export const mutations: MutationTree<RootState> = {
  SET_PAGE_CONTENT (state, payload) {
    state.content = payload;
  },
  SET_HOME_CONTENT (state, payload) {
    state.homeContent = payload;
  },
};

// ================================== ACTIONS ==================================

export const actions: ActionTree<RootState, RootState> = {
  _reset (ctx) {
    for (const MUTATION in mutations) {
      if (MUTATION.startsWith('SET_')) ctx.commit(MUTATION, undefined);
    };
  },

  async getHomePage (ctx, payload) {
    const { data } = await this.$axios.get(`/public/homepage?lang=${payload.lang}`);
    ctx.commit('SET_HOME_CONTENT', data);
    return data;
  },

  async getPage (ctx, payload) {
    const { data } = await this.$axios.get(`/public/pages/${payload.type}?lang=${payload.lang}`);
    ctx.commit('SET_PAGE_CONTENT', data);
    return data;
  },
};

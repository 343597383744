










































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { mapState } from 'vuex';

import { apiQueryBuilder } from '~/utils/common.js';

import GlobeAltSolid from '~/components/icons/GlobeAltSolid.vue';
import ChevronRightSolid from '~/components/icons/ChevronRightSolid.vue';
import Dropdown from '~/components/partials/Dropdown.vue';

@Component({
  components: { GlobeAltSolid, ChevronRightSolid, Dropdown },
  computed: {
    ...mapState('lang', {
      locales: 'locales',
      locale: 'locale',
      localeLabel: 'localeLabel',
    }),
    ...mapState('explorer', [
      'localisationTab',
      'destinationDestinationFilters',
      'destinationRoutesFilters',
      'destinationEventsFilters',
      'destinationProvidersFilters',
      'destinationTab',
    ]),
  },
})
export default class LanguageSwitcher extends Vue {
  @Prop(Boolean) openTopDirection: false;

  $refs: {
    languageBtn: HTMLButtonElement,
    chevron: Vue,
  };

  locales: Types.Locales;
  locale: string;
  localeLabel: string;
  localisationTab: string;
  destinationRoutesFilters: {[key: string]: any};
  destinationDestinationFilters: {[key: string]: any};
  destinationEventsFilters: {[key: string]: any};
  destinationProvidersFilters: {[key: string]: any};
  destinationTab: string;

  /*
    The method is emit when component is in MenuModal component, so we can close
    it. This method contains multiple logics that will be applied when user
    changes language.
  */
  @Emit('language-updated')
  async updateLanguage (language: Types.Locale) {
    // We will update Axeptio language
    const axeptioLocale: string = language.code === 'ca' ? 'es-ca' : language.code;

    const windowObject: any = window;
    // This method updates the language of the Axeptio widget
    try {
      windowObject.axeptioSDK.setCookiesVersion(`Cookies_${axeptioLocale}`);
    } catch (e) {
      console.warn("Error, axeptio can't change language because of your adblocker : " + e);
    }

    // Applying logic to update store values
    this.$store.commit('lang/SET_LANG', language.code);
    const defaultLabel: any = this.locales.find(locale => locale.code === language.code);
    this.$store.commit('lang/SET_LABEL', defaultLabel.name);

    // Reset sports as they are translated
    this.$store.commit('explorer/SET_SPORTS', []);

    // These conditions below ensure URL won't be reset when user changes
    // languages in explorer pages. As URL queries aren't real queries (just new
    // entries in history), they aren't saved when user changes languages. We
    // will so create a new URL that we will push to with existing filters.
    const routeName: any = this.$route?.name;
    if (
      routeName && routeName.includes(`explorer___`)
    ) {
      // Will fetch sports in the right language, after resetting them earlier
      await this.$store.dispatch('explorer/getSports', { lang: language.code });

      switch (this.localisationTab) {
        case 'destinations': {
          const queries = apiQueryBuilder(this.destinationDestinationFilters);
          return this.$router.push(`/${language.code}/explorer${queries}${queries ? '&' : '?'}tab=destinations`);
        }

        case 'routes': {
          const queries = apiQueryBuilder(this.destinationRoutesFilters);
          return this.$router.push(`/${language.code}/explorer${queries}${queries ? '&' : '?'}tab=routes`);
        }

        case 'events': {
          const queries = apiQueryBuilder(this.destinationEventsFilters);

          return this.$router.push(`/${language.code}/explorer${queries}${queries ? '&' : '?'}tab=events`);
        }
      }
    } else if (
      routeName && routeName.includes('explorer-destinations-destinationid-offer___')
    ) {
      const routeParams: any = this.$route.params;
      switch (this.destinationTab) {
        case 'routes': {
          const queries = apiQueryBuilder(this.destinationRoutesFilters);
          return this.$router.push(`/${language.code}/explorer/destinations/${routeParams.destinationid}/offer${queries}${queries ? '&' : '?'}tab=routes`);
        }

        case 'events': {
          const queries = apiQueryBuilder(this.destinationEventsFilters);
          return this.$router.push(`/${language.code}/explorer/destinations/${routeParams.destinationid}/offer${queries}${queries ? '&' : '?'}tab=events`);
        }

        case 'providers': {
          const queries = apiQueryBuilder(this.destinationProvidersFilters);
          return this.$router.push(`/${language.code}/explorer/destinations/${routeParams.destinationid}/offer${queries}${queries ? '&' : '?'}tab=providers`);
        }
      }
    } else {
      // Pushes to the new URL with new locale using nuxt-i18n methods
      this.$router.replace(this.switchLocalePath(language.code));
    }
  }

  async created () {
    // When component is mounted, we will display the right label based on
    // cookie value (already available as i18n logic is applied before)
    const defaultLabel: Types.Locale | undefined = this.locales.find(locale => locale.code === this.$cookies.get('i18n_redirected'));
    await this.$store.commit('lang/SET_LABEL', defaultLabel?.name);
  }

  /* Utils method to handle dropdown behavior if user clicks inside of it */
  handleClick (val: boolean) {
    if (!val) this.bluredBtn();
    else this.focusedBtn();
  }

  /* Update focused styles */
  focusedBtn () {
    this.$refs.languageBtn.classList.remove('border-gray-200');
    this.$refs.languageBtn.classList.add('border-gray-default');
    this.$refs.chevron.$el.classList.add('-rotate-180', '-rotate-90');
  }

  /* Closes active dropdown and update styles */
  bluredBtn () {
    this.$refs.languageBtn.classList.remove('border-gray-default');
    this.$refs.languageBtn.classList.add('border-gray-200');
    this.$refs.chevron.$el.classList.remove('-rotate-180', '-rotate-90');
  }
}

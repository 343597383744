






























import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';

import UserSolid from '~/components/icons/UserSolid.vue';
import MenuSolid from '~/components/icons/MenuSolid.vue';
import MenuModal from '~/components/modals/MenuModal.vue';

const Modal: Types.Dictionary<string> = {
  DISPLAY_MENU: 'display-menu',
};

@Component({
  components: { UserSolid, MenuSolid, MenuModal },
  computed: {
    ...mapState('user', {
      userInfos: 'me',
    }),
  },
})
export default class MenuCard extends Vue {
  @Prop(Boolean) userIsConnected: false;

  userInfos: Types.User;

  Modal = Modal;
  modal: string = '';
}

import { GetterTree, ActionTree, MutationTree } from 'vuex';

// =================================== STATE ===================================

const defaultLocale = 'en';

export const state: Types.LangState = () => ({
  locales: [
    {
      code: defaultLocale,
      name: 'English',
    },
    {
      code: 'fr',
      name: 'Français',
    },
    {
      code: 'es',
      name: 'Spanish',
    },
    {
      code: 'it',
      name: 'Italian',
    },
    {
      code: 'ca',
      name: 'Catalan',
    },
  ],
  locale: defaultLocale,
  localeLabel: 'English',
});

export type RootState = ReturnType<typeof state>

// ================================== GETTERS ==================================

export const getters: GetterTree<RootState, RootState> = {
  getLocale: (state) => {
    return state.locale || defaultLocale;
  },
};

// ================================= MUTATIONS =================================

export const mutations: MutationTree<RootState> = {
  SET_LANG (state, locale) {
    state.locale = locale;
  },
  SET_LABEL (state, label) {
    state.localeLabel = label;
  },
};

// ================================== ACTIONS ==================================

export const actions: ActionTree<RootState, RootState> = {
};



















import { Vue, Component, Prop } from 'vue-property-decorator';

import CartOutline from '~/components/icons/CartOutline.vue';
import EatOutline from '~/components/icons/EatOutline.vue';
import BedSolid from '~/components/icons/BedSolid.vue';
import BinocularSolid from '~/components/icons/BinocularSolid.vue';
import InfoOutline from '~/components/icons/InfoOutline.vue';

@Component({
})
export default class GlobalSearchFilteredProvider extends Vue {
  @Prop(Object) provider: Types.Provider;
  @Prop(Map) suggested: Types.mappingOfSuggested[];

  /* Classic switch to display associated provider icon (Vue component) */
  get providerIcon () {
    switch (this.provider.type) {
      case 'FOOD': return EatOutline;
      case 'SHOP': return CartOutline;
      case 'HOSTING': return BedSolid;
      case 'TODOTOSEE': return BinocularSolid;
      case 'SERVICE': return InfoOutline;
    }
  }

  /*
    The computed will display the destination name associated with this.suggested
    filtered element
  */
  get getDestinationTitle () {
    let foundDestination: string = "";
    this.suggested.forEach((s: any) => {
      if (s.id === this.provider.destination_id) foundDestination = s.title;
    });
    return foundDestination;
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"\n      flex flex-col md:flex-row relative cursor-pointer md:items-center\n      w-full md:bg-gray-50 md:border md:border-gray-200 md:rounded-2xl\n      justify-between\n    "},[_c('div',{staticClass:"w-5/6 border rounded-lg md:rounded-2xl md:hidden mb-2 bg-gray-50",on:{"click":function($event){return _vm.$emit('update-tab', 'destination')}}},[_c('EditableText',{staticClass:"p-3 rounded-lg font-semibold ",attrs:{"placeholder":_vm.$t('globalSearch.searchPlaceholder'),"value":(
            _vm.selectedLocalisation &&
            _vm.selectedLocalisation.properties &&
            _vm.selectedLocalisation.properties.name) ?
            _vm.selectedLocalisation.properties.name : _vm.text,"update":function (received) { return _vm.text = received; }},on:{"focus":function($event){return _vm.$emit('update-tab', 'destination')},"input":function($event){return _vm.$emit('search', $event)},"reset-selected":function($event){return _vm.$emit('reset-selected')}}})],1),_vm._v(" "),_c('div',{staticClass:"hidden md:inline-flex text-gray-400 font-semibold text-2xl w-full py-7 pl-6 select-none -my-px ",on:{"click":function($event){return _vm.$emit('update-tab', 'destination')}}},[_c('EditableText',{attrs:{"placeholder":_vm.$t('globalSearch.searchPlaceholder'),"value":(
            _vm.selectedLocalisation &&
            _vm.selectedLocalisation.properties &&
            _vm.selectedLocalisation.properties.name) ?
            _vm.selectedLocalisation.properties.name : _vm.text,"update":function (received) { return _vm.text = received; }},on:{"focus":function($event){return _vm.$emit('update-tab', 'destination')},"input":function($event){return _vm.$emit('search', $event)},"reset-selected":function($event){return _vm.$emit('reset-selected')}}})],1),_vm._v(" "),_c('div',{staticClass:"flex justify-end mr-4 hidden md:inline-flex"},[_c('button',{staticClass:"Btn Btn-filled flex items-center",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('launch-search')}}},[_c('SearchOutline',{staticClass:"w-5 h-5"}),_vm._v(" "),_c('span',{staticClass:"pl-3 font-semibold"},[_vm._v(_vm._s(_vm.$t('globalSearch.searchButton')))])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }
require('dayjs/locale/fr');
require('dayjs/locale/en');
require('dayjs/locale/ca');
require('dayjs/locale/es');
require('dayjs/locale/it');

export default ({ app, store, $dayjs }) => {
  const navigatorLanguage = app.i18n.getBrowserLocale();

  const locale = app.$cookies.get('i18n_redirected') || navigatorLanguage;
  if (!app.$cookies.get('i18n_redirected')) app.$cookies.set('i18n_redirected', locale);

  const defaultLabel = store.state.lang.locales.find(locale => locale.code === app.$cookies.get('i18n_redirected'));

  store.commit('lang/SET_LABEL', defaultLabel);
  store.commit('lang/SET_LANG', locale);
  $dayjs.locale(locale);
  app.i18n.setLocale(locale);
};
































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';

import SearchOutline from '~/components/icons/SearchOutline.vue';
import EditableText from '~/components/inputs/EditableText.vue';
import ProfileRunSportTag from '~/components/partials/ProfileRunSportTag.vue';
import EventCategoryTag from '~/components/partials/EventCategoryTag.vue';
import XSolid from '~/components/icons/XSolid.vue';

@Component({
  components: {
    SearchOutline,
    EditableText,
    ProfileRunSportTag,
    EventCategoryTag,
    XSolid,
  },
})
export default class GlobalSearchInput extends Vue {
  @Prop(Array) sports: [];
  @Prop([Object, String]) selectedLocalisation: Types.ReverseLocalisation | string;

  text: string = '';

  /*
    If input has been populated once, either by one search or by preselected
    data, we update this.text
  */
  @Watch('selectedLocalisation')
  function (val: Types.ReverseLocalisation | string) {
    if (typeof val === 'string') this.text = val;
  }
}

import { Middleware } from '@nuxt/types';

const localisationMapMiddleware: Middleware = async ({ app, store, route }) => {
  console.info('[localisationMapMiddleware]');

  const aroundMeQuery: any = route.query?.aroundMe;

  /*
    If user has accepted to share location, if the route contains a specific
    aroundMe query (double confirmation), we will fetch user coordinates with
    $localisation plugin (if they aren't already available in store)
  */
  if (
    !store.state.explorer.userCoords &&
    aroundMeQuery?.trim() === 'true'
  ) {
    await app.$localisation.getLocation()
      .then((coordinates: Types.userCoords) => {
        store.commit('explorer/SET_USER_COORDS', coordinates);
      })
      .catch((err: string) => {
        if (err) store.commit('explorer/SET_USER_COORDS', null);
      });
  }
};

export default localisationMapMiddleware;

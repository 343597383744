import { Middleware } from '@nuxt/types';
/**
* This middleware is meant for the email confirm page.
*/
const emailConfirmMiddleware: Middleware = async ({ store, query, error, app, $cookies }) => {
  console.info('[emailConfirmMiddleware]');
  store.commit('SET_GLOBALSEARCH_DISPLAY', false);

  if (!query.token) return error({ statusCode: 404 });

  /*
    This try/catch prevents the template to be rendered if route doesn't contains
    a matching query to reset user's email
  */
  try {
    await store.$axios.post(`/accounts/email-verify/confirm/${query.token}?lang=${store.state.lang.locale}`);
    store.commit('activities/RESET_ALL', null, { root: true });
  } catch (e) {
    const errorInstance: any = error;

    return errorInstance({
      statusCode: e.response.status,
      response: { data: { detail: e.response.data.detail } },
    });
  }
};

export default emailConfirmMiddleware;

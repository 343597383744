import { Middleware } from '@nuxt/types';

const homeMiddleware: Middleware = async ({ store }) => {
  console.info('[homeMiddleware]');

  await store.dispatch('website/getHomePage', { lang: store.state.lang.locale });
  await store.dispatch('explorer/getDestinationsHighlighted', store.state.lang.locale);
  await store.dispatch('explorer/getEventsSuggestions', { lang: store.state.lang.locale });
};

export default homeMiddleware;

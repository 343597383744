









































import { Vue, Component, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';

import TopMenu from '~/components/menus/TopMenu.vue';
import GlobalSearch from '~/components/modals/GlobalSearch.vue';
import ChevronRightSolid from '~/components/icons/ChevronRightSolid.vue';
import OptionsOutline from '~/components/icons/OptionsOutline.vue';

@Component({
  components: { TopMenu, GlobalSearch, ChevronRightSolid, OptionsOutline },
  computed: {
    ...mapState([
      'globalSearchModalIsDisplayed',
    ]),
    ...mapState('explorer', [
      'destinationTab',
      'destinationRoutes',
      'destinationEvents',
      'destinationProviders',
    ]),
  },
})
export default class DestinationMapLayout extends Vue {
  destinationTab: string;
  destinationRoutes: Types.RouteList;
  destinationEvents: Types.EventList;
  destinationProviders: Types.ProviderList;
  globalSearchModalIsDisplayed: boolean;

  tabToDisplay: any = this.localisationItems[0];

  get localisationItems () {
    return [
      {
        id: 'routes',
        name: this.$t('localisationItems.routes'),
        length: this.destinationRoutes?.total,
      },
      {
        id: 'events',
        name: this.$t('localisationItems.events'),
        length: this.destinationEvents?.total,
      },
      {
        id: 'providers',
        name: this.$t('localisationItems.providers'),
        length: this.destinationProviders?.total,
      },
    // { id: 'articles', name: 'articles' },
    ];
  }

  /*
    When state value is updated, we trigger this.handleChange to update
    template style
  */
  @Watch('destinationTab')
  function (val: string) {
    this.handleChange(this.localisationItems.find((d: { id: string, name: string, length: number }) => d.id === val) || this.localisationItems[0]);
  }

  mounted () {
    document.body.classList.add('overflow-y-hidden');

    // This condition displays or not the map based on route query as a
    // preselected value
    if (this.$route.query?.tab) {
      if (this.$route.query?.tab === 'articles') {
        this.$store.commit('explorer/SET_LOCALISATION_MAP_DISPLAY', false);
      }
      this.$store.commit('explorer/SET_DESTINATION_TAB', this.$route.query?.tab);
      this.tabToDisplay = this.localisationItems.find((item: any) => item.id === this.destinationTab) || 'routes';
    }
  }

  beforeDestroy () {
    document.body.classList.remove('overflow-y-hidden');

    this.$store.commit('explorer/SET_HAS_CHANGED_LANGUAGE', false);
  }

  /*
    This method will update the tab when user selects one, and updates style
    based on window inner width
  */
  handleChange (item: { id: string, name: string, length: number }) {
    if (process.client) {
      if (window.innerWidth > 768) {
        this.$store.commit('explorer/SET_LOCALISATION_MAP_DISPLAY', true);
      }
      this.tabToDisplay = item;
      this.$store.commit('explorer/SET_DESTINATION_TAB', item.id);

      if (item.id === 'articles') {
        this.$store.commit('explorer/SET_LOCALISATION_MAP_DISPLAY', false);
      }
    }
  }
}






























import { Vue, Component, Emit } from 'vue-property-decorator';

@Component({})
export default class MobileDownloadFixedFooter extends Vue {
  deviceType: string | null = null;

  mounted () {
    this.getDeviceType();
    this.$store.commit("SET_MOBILE_DOWNLOAD_BANNER_DISPLAY", true);
  }

  getDeviceType () {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      this.deviceType = "iphone";
    } else {
      this.deviceType = "android";
    }
  }

  get downloadLink (): string {
    if (this.deviceType === "iphone") {
      return "https://apps.apple.com/fr/app/on-piste/id1597319695";
    } else {
      return "https://play.google.com/store/apps/details?id=com.rossignol.onpiste";
    }
  }

  @Emit('close-mobile-download-fixed-footer')
  closeMobileDownloadFixedFooter () {
    sessionStorage.setItem('mobileDownloadFixedFooterClosed', 'true');
    this.$store.commit("SET_MOBILE_DOWNLOAD_BANNER_DISPLAY", false);
  }
}

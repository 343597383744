/**
* This middleware must be used in routes where we require NO authentication.
*/
import { Middleware } from '@nuxt/types';

const noauthMiddleware: Middleware = async ({ store, redirect, route, app }) => {
  console.info('[noauthMiddleware]');
  store.commit('SET_GLOBALSEARCH_DISPLAY', false);

  /*
    If user doesn't have a default unit in cookies, we
    store it based on browser locale
  */
  if (!app.$cookies.get('unit')) {
    if (app.i18n.getBrowserLocale() === 'en-US') app.$cookies.set('unit', 'imperial');
    else app.$cookies.set('unit', 'metric');
  }

  /*
    The middleware will try to see :
    If token bearer provided when signin is correct, then ;
    If loadProfile action returns a defined object, then ;
    User will be considered connected, and based on actual route,
    will be redirected.
  */
  if (await store.dispatch('user/loadToken')) {
    if (await store.dispatch('user/loadProfile', { useCache: true })) {
      if (
        route.name &&
        (
          route.name.includes('signup-') ||
          route.name.includes('account-') ||
          (route.name.includes('signin') && !route.query?.signout)
        )
      ) {
        return redirect('/');
      }
    }
  }
};

export default noauthMiddleware;

import { injectScript } from '~/utils/dom';

// XXX: This could be added in the store to allow signing out from provider (on our website)
var loading: Promise<Types.GoogleAuth> | undefined;
var ready: boolean = false;

const SCRIPT_ID = "gapi-script";
const SCRIPT_SRC = "https://apis.google.com/js/platform.js";

export function loadGoogleAuth (params: Types.GapiAuth2Params): Promise<Types.GoogleAuth> {
  const googleAuth: Types.GoogleAuth | undefined = window?.gapi?.auth2?.getAuthInstance();
  if (googleAuth) {
    return Promise.resolve(googleAuth);
  }
  const initGapi = (): Promise<Types.GoogleAuth> => {
    return new Promise((resolve, reject) => {
      // This can fail on some browsers. Either because the browser is too old.
      // Or because the browser (e.g. firefox) prevents arguable cookie behavior.
      // XXX: It could be better to inform the user that the browser (or rather google)
      // is responsible.
      window.gapi.auth2.init(params)
        .then(() => resolve(window.gapi.auth2.getAuthInstance()), (err) => {
          console.error(err);
          reject(err);
        });
    });
  };
  if (ready) {
    return initGapi();
  }
  // Prepare the Gapi loader that will be executed on script load
  if (!loading) {
    loading = new Promise((resolve, reject) => {
      // Inject the Gapi auth2 sdk to the document head (if not done, already).
      const el = injectScript(SCRIPT_ID, SCRIPT_SRC);
      el.onload = () => window.gapi.load('auth2', () => {
        ready = true;
        resolve(initGapi());
      });
    });
  }
  return loading;
}

export async function getGoogleUser (auth: Types.GoogleAuth): Promise<Types.GoogleUser> {
  if (!auth) {
    throw new Error("You must load the Gapi first.");
  }
  if (auth.isSignedIn.get()) {
    return auth.currentUser.get();
  }
  return await auth.signIn();
}

export async function signOutGapi (client_id: string) {
  const googleAuth = await loadGoogleAuth({ client_id });
  if (googleAuth.isSignedIn.get()) {
    googleAuth.signOut();
  }
}

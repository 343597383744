


















import { Vue, Component, Prop } from 'vue-property-decorator';

import CalendarOutline from '~/components/icons/CalendarOutline.vue';

@Component({
  components: { CalendarOutline },
})
export default class GlobalSearchFilteredElement extends Vue {
  @Prop(Object) item: any;
  @Prop(Function) icon: Function;
  @Prop(Array) destinationNames: [];
  @Prop(String) resultLink: '';
  @Prop(Map) suggested: Types.mappingOfSuggested[];

  /*
    The computed will display the destination name associated with this.suggested
    filtered element
  */
  get getDestinationTitle () {
    let foundDestination: string = "";
    foundDestination = this.item.destinations[0].title ? this.item.destinations[0].title : "";
    return foundDestination;
  }
}

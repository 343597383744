import Vue from 'vue';

import infiniteScroll from 'vue-infinite-scroll';
import vueDebounce from 'vue-debounce';
import VueGeolocation from 'vue-browser-geolocation';
import VueScrollactive from 'vue-scrollactive';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

Vue.component('VueSlider', VueSlider);
Vue.use(VueScrollactive);
Vue.use(VueGeolocation);
Vue.use(infiniteScroll);
Vue.use(vueDebounce);

export default ({ $config, store, app }) => {
  window.mtcaptchaConfig = {
    "sitekey": $config.MT_CAPTCHA_SITE_KEY,
    "lang": store.state.lang.locale,
    "render": "explicit",
    "renderQueue": [],
  };
  (function () {
    var mt_service = document.createElement('script'); mt_service.async = true; mt_service.src = 'https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js'; (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(mt_service);
    var mt_service2 = document.createElement('script'); mt_service2.async = true; mt_service2.src = 'https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha2.min.js'; (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(mt_service2);
  })();

  (function (w, d, s, l, i) {
    w[l] = w[l] || []; w[l].push({
      'gtm.start':
      new Date().getTime(),
      event:'gtm.js',
    }); var f = d.getElementsByTagName(s)[0];
    var j = d.createElement(s); var dl = l !== 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
    'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', $config.GOOGLE_TAG_MANAGER_ID);
};

import { Middleware } from '@nuxt/types';
/**
* This middleware is meant for the password reset page.
*/
const passwordResetMiddleware: Middleware = ({ store, query, error }) => {
  console.info('[passwordResetMiddleware]');
  store.commit('SET_GLOBALSEARCH_DISPLAY', false);

  if (!query.token) return error({ statusCode: 404 });
};

export default passwordResetMiddleware;

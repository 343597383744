export const countriesCoordinates = [
  {
    "country": "Afghanistan",
    "countryCode": "AF",
    "long": 33.93911,
    "lat": 67.709953,
    "bounds": [60.50526, 29.394159, 74.898827, 38.453041],
  },
  {
    "country": "Albania",
    "countryCode": "AL",
    "long": 41.153332,
    "lat": 20.168331,
    "bounds": [19.28249, 39.667759, 21.053049, 42.661369],
  },
  {
    "country": "Algeria",
    "countryCode": "DZ",
    "long": 28.033886,
    "lat": 1.659626,
    "bounds": [-8.669294, 18.976379, 11.98645, 37.083599],
  },
  {
    "country": "American Samoa",
    "countryCode": "AS",
    "long": -14.270972,
    "lat": -170.132217,
    "bounds": [-170.823931, -14.376303, -170.560398, -14.25428],
  },
  {
    "country": "Andorra",
    "countryCode": "AD",
    "long": 42.546245,
    "lat": 1.601554,
    "bounds": [1.435247, 42.4361, 1.781667, 42.65387],
  },
  {
    "country": "Angola",
    "countryCode": "AO",
    "long": -11.202692,
    "lat": 17.873887,
    "bounds": [11.73111, -18.01173, 24.06221, -4.388889],
  },
  {
    "country": "Antigua and Barbuda",
    "countryCode": "AG",
    "long": 17.060816,
    "lat": -61.796428,
    "bounds": [-61.891102, 16.9897, -61.666401, 17.725],
  },
  {
    "country": "Argentina",
    "countryCode": "AR",
    "long": -38.416097,
    "lat": -63.616672,
    "bounds": [-73.58168, -55.04528, -53.65028, -21.78751],
  },
  {
    "country": "Armenia",
    "countryCode": "AM",
    "long": 40.069099,
    "lat": 45.038189,
    "bounds": [43.47324, 38.844219, 46.622501, 41.298599],
  },
  {
    "country": "Aruba",
    "countryCode": "AW",
    "long": 12.52111,
    "lat": -69.968338,
    "bounds": [-70.06, 12.4, -69.863, 12.63],
  },
  {
    "country": "Australia",
    "countryCode": "AU",
    "long": -25.274398,
    "lat": 133.775136,
    "bounds": [112.908302, -43.64806, 153.619995, -10.71056],
  },
  {
    "country": "Austria",
    "countryCode": "AT",
    "long": 47.516231,
    "lat": 14.550072,
    "bounds": [9.54041, 46.387489, 17.179899, 49.01582],
  },
  {
    "country": "Azerbaijan",
    "countryCode": "AZ",
    "long": 40.143105,
    "lat": 47.576927,
    "bounds": [44.77858, 38.390541, 50.384991, 41.896702],
  },
  {
    "country": "Bahamas",
    "countryCode": "BS",
    "long": 25.03428,
    "lat": -77.39628,
    "bounds": [-78.80307, 22.256941, -73.829453, 26.928329],
  },
  {
    "country": "Bahrain",
    "countryCode": "BH",
    "long": 25.930414,
    "lat": 50.637772,
    "bounds": [50.466099, 25.79055, 50.613041, 26.24028],
  },
  {
    "country": "Bangladesh",
    "countryCode": "BD",
    "long": 23.684994,
    "lat": 90.356331,
    "bounds": [88.043297, 20.7486, 92.669418, 26.625271],
  },
  {
    "country": "Barbados",
    "countryCode": "BB",
    "long": 13.193887,
    "lat": -59.543198,
    "bounds": [-59.65918, 13.05694, -59.429169, 13.33194],
  },
  {
    "country": "Belarus",
    "countryCode": "BY",
    "long": 53.709807,
    "lat": 27.953389,
    "bounds": [19.636511, 51.2528, 32.741402, 56.167801],
  },
  {
    "country": "Belgium",
    "countryCode": "BE",
    "long": 50.503887,
    "lat": 4.469936,
    "bounds": [2.541667, 49.5061, 6.400277, 51.502769],
  },
  {
    "country": "Belize",
    "countryCode": "BZ",
    "long": 17.189877,
    "lat": -88.49765,
    "bounds": [-89.217773, 15.88972, -88.08223, 18.48971],
  },
  {
    "country": "Benin",
    "countryCode": "BJ",
    "long": 9.30769,
    "lat": 2.315834,
    "bounds": [0.776667, 6.210679, 3.855, 12.39332],
  },
  {
    "country": "Bermuda",
    "countryCode": "BM",
    "long": 32.321384,
    "lat": -64.75737,
    "bounds": [-64.858299, 32.260601, -64.6717, 32.382198],
  },
  {
    "country": "Bhutan",
    "countryCode": "BT",
    "long": 27.514162,
    "lat": 90.433601,
    "bounds": [88.750549, 26.70138, 92.11441, 28.32333],
  },
  {
    "country": "Bolivia",
    "countryCode": "BO",
    "long": -16.290154,
    "lat": -63.588653,
    "bounds": [-69.652496, -22.901119, -57.45861, -9.682777],
  },
  {
    "country": "Bosnia - Herzegovina",
    "countryCode": "BA",
    "long": 43.915886,
    "lat": 17.679076,
    "bounds": [15.7364, 42.564522, 19.619699, 45.276901],
  },
  {
    "country": "Botswana",
    "countryCode": "BW",
    "long": -22.328474,
    "lat": 24.684866,
    "bounds": [19.995819, -26.89468, 29.370529, -17.797661],
  },
  {
    "country": "Brazil",
    "countryCode": "BR",
    "long": -14.235004,
    "lat": -51.92528,
    "bounds": [-74.009453, -33.74773, -34.79945, 5.273609],
  },
  {
    "country": "British Virgin Island",
    "countryCode": "VG",
    "long": 18.420695,
    "lat": -64.639968,
    "bounds": [-64.522117, 18.016647, -63.0033, 18.450594],
  },
  {
    "country": "Brunei",
    "countryCode": "BN",
    "long": 4.535277,
    "lat": 114.727669,
    "bounds": [114.091003, 4.027776, 115.360199, 5.053054],
  },
  {
    "country": "Bulgaria",
    "countryCode": "BG",
    "long": 42.733883,
    "lat": 25.48583,
    "bounds": [22.35944, 41.254429, 28.60638, 44.243061],
  },
  {
    "country": "Burkina Faso",
    "countryCode": "BF",
    "long": 12.238333,
    "lat": -1.561593,
    "bounds": [-5.52066, 9.396385, 2.401563, 15.08305],
  },
  {
    "country": "Burundi",
    "countryCode": "BI",
    "long": -3.373056,
    "lat": 29.918886,
    "bounds": [28.98749, -4.454723, 30.85388, -2.299445],
  },
  {
    "country": "Cambodia",
    "countryCode": "KH",
    "long": 12.565679,
    "lat": 104.990963,
    "bounds": [102.351402, 10.00972, 107.636398, 14.70496],
  },
  {
    "country": "Cameroon United Republic",
    "countryCode": "CM",
    "long": 7.369722,
    "lat": 12.354722,
    "bounds": [8.502497, 1.654166, 16.206409, 13.08472],
  },
  {
    "country": "Canada",
    "countryCode": "CA",
    "long": 56.130366,
    "lat": -106.346771,
    "bounds": [-141.001404, 41.913319, -52.616669, 83.113327],
  },
  {
    "country": "Cape Verde",
    "countryCode": "CV",
    "long": 16.002082,
    "lat": -24.013197,
    "bounds": [-25.36056, 14.81222, -22.6725, 17.19166],
  },
  {
    "country": "Central African Republic",
    "countryCode": "CF",
    "long": 6.611111,
    "lat": 20.939444,
    "bounds": [14.41444, 2.221102, 27.45805, 11.00139],
  },
  {
    "country": "Chad",
    "countryCode": "TD",
    "long": 15.454166,
    "lat": 18.732207,
    "bounds": [13.46222, 7.457776, 23.99971, 23.45055],
  },
  {
    "country": "Chile",
    "countryCode": "CL",
    "long": -35.675147,
    "lat": -71.542969,
    "bounds": [-75.715561, -55.72057, -67.001106, -17.50528],
  },
  {
    "country": "China",
    "countryCode": "CN",
    "long": 35.86166,
    "lat": 104.195397,
    "bounds": [73.623863, 18.168881, 134.772201, 53.553871],
  },
  {
    "country": "Colombia",
    "countryCode": "CO",
    "long": 4.570868,
    "lat": -74.297333,
    "bounds": [-79.051681, -4.224228, -66.873383, 12.45833],
  },
  {
    "country": "Comoros",
    "countryCode": "KM",
    "long": -11.875001,
    "lat": 43.872219,
    "bounds": [43.214432, -12.37084, 44.526661, -11.37111],
  },
  {
    "country": "Congo",
    "countryCode": "CD",
    "long": -4.038333,
    "lat": 21.758664,
    "bounds": [11.14151, -5.020615, 18.642219, 3.710833],
  },
  {
    "country": "Congo",
    "countryCode": "CG",
    "long": -0.228021,
    "lat": 15.827659,
    "bounds": [12.20966, -13.45195, 31.277451, 5.347498],
  },
  {
    "country": "Costa Rica",
    "countryCode": "CR",
    "long": 9.748917,
    "lat": -83.753428,
    "bounds": [-85.89862, 8.026709, -82.565193, 11.21361],
  },
  {
    "country": "Cote D'ivoire",
    "countryCode": "CI",
    "long": 7.539989,
    "lat": -5.54708,
    "bounds": [-8.606667, 4.347776, -2.488056, 10.73495],
  },
  {
    "country": "Croatia",
    "countryCode": "HR",
    "long": 45.1,
    "lat": 15.2,
    "bounds": [13.49639, 42.398319, 19.4261, 46.541698],
  },
  {
    "country": "Cuba",
    "countryCode": "CU",
    "long": 21.521757,
    "lat": -77.781167,
    "bounds": [-84.953339, 19.821939, -74.131943, 23.18638],
  },
  {
    "country": "Cyprus",
    "countryCode": "CY",
    "long": 35.126413,
    "lat": 33.429859,
    "bounds": [32.269161, 34.56332, 34.581661, 35.67165],
  },
  {
    "country": "Czech Republic",
    "countryCode": "CZ",
    "long": 49.817492,
    "lat": 15.472962,
    "bounds": [12.09347, 48.57555, 18.871652, 51.031368],
  },
  {
    "country": "Denmark",
    "countryCode": "DK",
    "long": 56.26392,
    "lat": 9.501785,
    "bounds": [8.091389, 54.570271, 12.61806, 57.74416],
  },
  {
    "country": "Djibouti",
    "countryCode": "DJ",
    "long": 11.825138,
    "lat": 42.590275,
    "bounds": [41.759708, 10.97083, 43.419991, 12.70889],
  },
  {
    "country": "Dominica",
    "countryCode": "DM",
    "long": 15.414999,
    "lat": -61.370976,
    "bounds": [-61.490009, 15.2, -61.25362, 15.62805],
  },
  {
    "country": "Dominican Republic",
    "countryCode": "DO",
    "long": 18.735693,
    "lat": -70.162651,
    "bounds": [-71.991524, 17.61833, -68.322243, 19.928329],
  },
  {
    "country": "Ecuador",
    "countryCode": "EC",
    "long": -1.831239,
    "lat": -78.183406,
    "bounds": [-91.666122, -5.003335, -75.218613, 1.437926],
  },
  {
    "country": "Egypt",
    "countryCode": "EG",
    "long": 26.820553,
    "lat": 30.802498,
    "bounds": [24.706381, 21.99666, 35.813042, 31.63933],
  },
  {
    "country": "El Salvador",
    "countryCode": "SV",
    "long": 13.794185,
    "lat": -88.89653,
    "bounds": [-90.105827, 13.15666, -87.695267, 14.45166],
  },
  {
    "country": "Equatorial Guinea",
    "countryCode": "GQ",
    "long": 1.650801,
    "lat": 10.267895,
    "bounds": [9.357498, 1.001944, 11.35389, 2.346065],
  },
  {
    "country": "Eritrea",
    "countryCode": "ER",
    "long": 15.179384,
    "lat": 39.782334,
    "bounds": [36.443859, 12.36389, 43.12331, 18.0089],
  },
  {
    "country": "Estonia",
    "countryCode": "EE",
    "long": 58.595272,
    "lat": 25.013607,
    "bounds": [21.83194, 57.522202, 28.195299, 59.66832],
  },
  {
    "country": "Ethiopia",
    "countryCode": "ET",
    "long": 9.145,
    "lat": 40.489673,
    "bounds": [32.989429, 3.406666, 47.98943, 14.867343],
  },
  {
    "country": "Falkland Islands",
    "countryCode": "FK",
    "long": -51.796253,
    "lat": -59.523613,
    "bounds": [-61.030811, -52.339451, -57.731392, -51.25861],
  },
  {
    "country": "Faroe Islands",
    "countryCode": "FO",
    "long": 61.892635,
    "lat": -6.911806,
    "bounds": [-7.2325, 61.933319, -6.5675, 62.330269],
  },
  {
    "country": "Fiji",
    "countryCode": "FJ",
    "long": -16.578193,
    "lat": 179.414413,
    "bounds": [177.256302, -19.1614, 179.989502, -16.152691],
  },
  {
    "country": "Finland",
    "countryCode": "FI",
    "long": 61.92411,
    "lat": 25.748151,
    "bounds": [19.648331, 59.804989, 31.587299, 70.079422],
  },
  {
    "country": "France",
    "countryCode": "FR",
    "long": 46.227638,
    "lat": 2.213749,
    "bounds": [-4.790556, 41.366379, 9.543055, 51.091099],
  },
  {
    "country": "French Guiana",
    "countryCode": "GF",
    "long": 3.933889,
    "lat": -53.125782,
    "bounds": [-54.61536, 2.113055, -51.649441, 5.750277],
  },
  {
    "country": "French Polynesia",
    "countryCode": "PF",
    "long": -17.679742,
    "lat": -149.406843,
    "bounds": [-149.641693, -17.87084, -149.148895, -17.499451],
  },
  {
    "country": "French Southern and Antarctic Lands",
    "countryCode": "TF",
    "long": -49.280366,
    "lat": 69.348557,
    "bounds": [68.751373, -49.720558, 70.568588, -48.652779],
  },
  {
    "country": "Gabon",
    "countryCode": "GA",
    "long": -0.803689,
    "lat": 11.609444,
    "bounds": [8.706387, -3.922898, 14.51861, 2.317276],
  },
  {
    "country": "Gambia",
    "countryCode": "GM",
    "long": 13.443182,
    "lat": -15.310139,
    "bounds": [-16.826389, 13.03709, -13.79861, 13.82638],
  },
  {
    "country": "Gaza Strip",
    "countryCode": "GZ",
    "long": 31.354676,
    "lat": 34.308825,
    "bounds": [34.21748, 31.216261, 34.55859, 31.596661],
  },
  {
    "country": "Georgia",
    "countryCode": "GE",
    "long": 42.315407,
    "lat": 43.356892,
    "bounds": [40.006512, 41.046101, 46.7108, 43.584702],
  },
  {
    "country": "Germany",
    "countryCode": "DE",
    "long": 51.165691,
    "lat": 10.451526,
    "bounds": [5.869443, 47.281101, 15.03805, 54.9174],
  },
  {
    "country": "Ghana",
    "countryCode": "GH",
    "long": 7.946527,
    "lat": -1.023194,
    "bounds": [-3.249166, 4.727777, 1.20385, 11.16666],
  },
  {
    "country": "Greece",
    "countryCode": "GR",
    "long": 39.074208,
    "lat": 21.824312,
    "bounds": [19.627501, 34.929989, 28.24222, 41.744431],
  },
  {
    "country": "Greenland",
    "countryCode": "GL",
    "long": 71.706936,
    "lat": -42.604303,
    "bounds": [-73.043083, 59.7911, -12.15528, 83.623032],
  },
  {
    "country": "Grenada",
    "countryCode": "GD",
    "long": 12.262776,
    "lat": -61.604171,
    "bounds": [-61.789699, 11.9964, -61.596401, 12.2378],
  },
  {
    "country": "Guadeloupe",
    "countryCode": "GP",
    "long": 16.995971,
    "lat": -62.067641,
    "bounds": [-61.783619, 15.95, -61.10992, 16.513611],
  },
  {
    "country": "Guatemala",
    "countryCode": "GT",
    "long": 15.783471,
    "lat": -90.230759,
    "bounds": [-92.245941, 13.74547, -88.213059, 17.82111],
  },
  {
    "country": "Guinea",
    "countryCode": "GN",
    "long": 9.945587,
    "lat": -9.696645,
    "bounds": [-15.07861, 3.21361, 8.961386, 12.66603],
  },
  {
    "country": "Guinea-Bissau",
    "countryCode": "GW",
    "long": 11.803749,
    "lat": -15.180413,
    "bounds": [-16.677811, 10.92389, -13.64417, 12.68472],
  },
  {
    "country": "Guyana",
    "countryCode": "GY",
    "long": 4.860416,
    "lat": -58.93018,
    "bounds": [-61.389999, 1.185555, -56.470909, 8.533598],
  },
  {
    "country": "Haiti",
    "countryCode": "HT",
    "long": 18.971187,
    "lat": -72.285215,
    "bounds": [-74.476959, 18.02277, -71.62944, 19.93277],
  },
  {
    "country": "Heard Island and McDonald Islands",
    "countryCode": "HM",
    "long": -53.08181,
    "lat": 73.504158,
    "bounds": [73.234421, -53.199169, 73.719422, -52.96418],
  },
  {
    "country": "Honduras",
    "countryCode": "HN",
    "long": 15.199999,
    "lat": -86.241905,
    "bounds": [-89.354874, 12.98741, -83.130867, 16.020269],
  },
  {
    "country": "Hong Kong",
    "countryCode": "HK",
    "long": 22.396428,
    "lat": 114.109497,
    "bounds": [113.896599, 22.2775, 114.389999, 22.548849],
  },
  {
    "country": "Hungary",
    "countryCode": "HU",
    "long": 47.162494,
    "lat": 19.503304,
    "bounds": [16.106701, 45.760349, 22.894859, 48.575821],
  },
  {
    "country": "Iceland",
    "countryCode": "IS",
    "long": 64.963051,
    "lat": -19.020835,
    "bounds": [-24.515, 63.40276, -13.49778, 66.534416],
  },
  {
    "country": "India",
    "countryCode": "IN",
    "long": 20.593684,
    "lat": 78.96288,
    "bounds": [68.139977, 6.748887, 97.394127, 35.986099],
  },
  {
    "country": "Indonesia",
    "countryCode": "ID",
    "long": -0.789275,
    "lat": 113.921327,
    "bounds": [95.198593, -10.93, 141.008301, 5.653888],
  },
  {
    "country": "Iran",
    "countryCode": "IR",
    "long": 32.427908,
    "lat": 53.688046,
    "bounds": [44.034161, 25.07527, 63.341931, 39.78054],
  },
  {
    "country": "Iraq",
    "countryCode": "IQ",
    "long": 33.223191,
    "lat": 43.679291,
    "bounds": [38.794552, 29.10416, 48.545181, 37.38443],
  },
  {
    "country": "Ireland",
    "countryCode": "IE",
    "long": 53.41291,
    "lat": -8.24389,
    "bounds": [-10.47278, 51.44582, -5.994722, 55.377209],
  },
  {
    "country": "Isle of Man",
    "countryCode": "IM",
    "long": 54.236107,
    "lat": -4.548056,
    "bounds": [-4.784167, 54.056931, -4.308612, 54.409161],
  },
  {
    "country": "Israel",
    "countryCode": "IL",
    "long": 31.046051,
    "lat": 34.851612,
    "bounds": [34.267078, 29.49379, 35.669708, 33.290272],
  },
  {
    "country": "Italy",
    "countryCode": "IT",
    "long": 41.87194,
    "lat": 12.56738,
    "bounds": [6.620172, 36.650829, 18.51083, 47.090542],
  },
  {
    "country": "Jamaica",
    "countryCode": "JM",
    "long": 18.109581,
    "lat": -77.297508,
    "bounds": [-78.372787, 17.696659, -76.188606, 18.52194],
  },
  {
    "country": "Japan",
    "countryCode": "JP",
    "long": 36.204824,
    "lat": 138.252924,
    "bounds": [127.652801, 26.07888, 145.818298, 45.50552],
  },
  {
    "country": "Jordan",
    "countryCode": "JE",
    "long": 49.214439,
    "lat": -2.13125,
    "bounds": [34.88443, 29.188881, 39.303081, 33.377232],
  },
  {
    "country": "Kazakhstan",
    "countryCode": "KZ",
    "long": 48.019573,
    "lat": 66.923684,
    "bounds": [46.499199, 40.591099, 87.345329, 55.444698],
  },
  {
    "country": "Kenya",
    "countryCode": "KE",
    "long": -0.023559,
    "lat": 37.906193,
    "bounds": [33.951382, -4.673122, 41.913639, 4.622081],
  },
  {
    "country": "Kiribati",
    "countryCode": "KI",
    "long": -3.370417,
    "lat": -168.734039,
    "bounds": [-157.5681, 1.705, -157.191101, 2.026666],
  },
  {
    "country": "Kosovo",
    "countryCode": "XK",
    "long": 42.602636,
    "lat": 20.902977,
    "bounds": [19.9753, 41.855598, 21.8183, 43.268101],
  },
  {
    "country": "Kuwait",
    "countryCode": "KW",
    "long": 29.31166,
    "lat": 47.481766,
    "bounds": [46.541611, 28.544359, 48.419731, 30.083879],
  },
  {
    "country": "Kyrgyzstan",
    "countryCode": "KG",
    "long": 41.20438,
    "lat": 74.766098,
    "bounds": [69.248901, 39.191898, 80.285477, 43.2686],
  },
  {
    "country": "Laos",
    "countryCode": "LA",
    "long": 19.85627,
    "lat": 102.495496,
    "bounds": [100.0923, 13.92, 107.695198, 22.50083],
  },
  {
    "country": "Latvia",
    "countryCode": "LV",
    "long": 56.879635,
    "lat": 24.603189,
    "bounds": [20.986941, 55.674999, 28.237801, 58.0844],
  },
  {
    "country": "Lebanon",
    "countryCode": "LB",
    "long": 33.854721,
    "lat": 35.862285,
    "bounds": [35.097061, 33.063042, 36.624981, 34.689152],
  },
  {
    "country": "Lesotho",
    "countryCode": "LS",
    "long": -29.609988,
    "lat": 28.233608,
    "bounds": [27.014721, -30.65889, 29.455549, -28.56945],
  },
  {
    "country": "Liberia",
    "countryCode": "LR",
    "long": 6.428055,
    "lat": -9.429499,
    "bounds": [-11.4987, 4.351451, -7.367778, 8.569677],
  },
  {
    "country": "Libya",
    "countryCode": "LY",
    "long": 26.3351,
    "lat": 17.228331,
    "bounds": [9.303886, 19.497761, 25.13423, 33.165779],
  },
  {
    "country": "Liechtenstein",
    "countryCode": "LI",
    "long": 47.166,
    "lat": 9.555373,
    "bounds": [9.47583, 47.049641, 9.640949, 47.266979],
  },
  {
    "country": "Lithuania",
    "countryCode": "LT",
    "long": 55.169438,
    "lat": 23.881275,
    "bounds": [20.93203, 53.8881, 26.8197, 56.450802],
  },
  {
    "country": "Luxembourg",
    "countryCode": "LU",
    "long": 49.815273,
    "lat": 6.129583,
    "bounds": [5.731111, 49.448318, 6.522222, 50.172211],
  },
  {
    "country": "Macau",
    "countryCode": "MO",
    "long": 22.198745,
    "lat": 113.543873,
    "bounds": [113.527496, 22.0917, 113.603897, 22.214399],
  },
  {
    "country": "Macedonia",
    "countryCode": "MK",
    "long": 41.608635,
    "lat": 21.745275,
    "bounds": [20.46249, 40.857021, 23.031389, 42.361401],
  },
  {
    "country": "Madagascar",
    "countryCode": "MG",
    "long": -18.766947,
    "lat": 46.869107,
    "bounds": [43.2211, -25.600281, 50.499439, -11.94778],
  },
  {
    "country": "Malawi",
    "countryCode": "MW",
    "long": -13.254308,
    "lat": 34.301525,
    "bounds": [32.689701, -17.135811, 35.92416, -9.373335],
  },
  {
    "country": "Malaysia",
    "countryCode": "MY",
    "long": 4.210484,
    "lat": 101.975766,
    "bounds": [99.646927, 0.853333, 119.275803, 7.356666],
  },
  {
    "country": "Maldives",
    "countryCode": "MV",
    "long": 3.202778,
    "lat": 73.22068,
    "bounds": [72.683601, -0.6908, 73.753601, 7.0964],
  },
  {
    "country": "Mali",
    "countryCode": "ML",
    "long": 17.570692,
    "lat": -3.996166,
    "bounds": [-12.24575, 10.14194, 4.2525, 25.000271],
  },
  {
    "country": "Malta",
    "countryCode": "MT",
    "long": 35.937496,
    "lat": 14.375416,
    "bounds": [14.1808, 35.799999, 14.57, 36.075001],
  },
  {
    "country": "Martinique",
    "countryCode": "MQ",
    "long": 14.641528,
    "lat": -61.024174,
    "bounds": [-61.22945, 14.40305, -60.810009, 14.88028],
  },
  {
    "country": "Mauritania",
    "countryCode": "MR",
    "long": 21.00789,
    "lat": -10.940835,
    "bounds": [-17.05435, 14.72555, -4.806389, 27.280199],
  },
  {
    "country": "Mauritius",
    "countryCode": "MU",
    "long": -20.348404,
    "lat": 57.552152,
    "bounds": [57.306381, -20.50584, 57.78582, -20.00112],
  },
  {
    "country": "Mexico",
    "countryCode": "MX",
    "long": 23.634501,
    "lat": -102.552784,
    "bounds": [-117.123596, 14.54808, -86.74501, 32.718319],
  },
  {
    "country": "Moldova",
    "countryCode": "MD",
    "long": 47.411631,
    "lat": 28.369885,
    "bounds": [26.635, 45.4482, 30.134199, 48.486698],
  },
  {
    "country": "Mongolia",
    "countryCode": "MN",
    "long": 46.862496,
    "lat": 103.846656,
    "bounds": [87.759407, 41.581379, 119.928001, 52.14201],
  },
  {
    "country": "Montenegro",
    "countryCode": "ME",
    "long": 42.708678,
    "lat": 19.37439,
    "bounds": [18.4533, 41.849319, 20.382799, 43.556099],
  },
  {
    "country": "Morocco",
    "countryCode": "MA",
    "long": 31.791702,
    "lat": -7.09262,
    "bounds": [-13.176931, 27.665079, -1.010556, 35.915272],
  },
  {
    "country": "Mozambique",
    "countryCode": "MZ",
    "long": -18.665695,
    "lat": 35.529562,
    "bounds": [30.21277, -26.860279, 40.839161, -10.48045],
  },
  {
    "country": "Myanmar",
    "countryCode": "MM",
    "long": 21.913965,
    "lat": 95.956223,
    "bounds": [92.193298, 9.985552, 101.161598, 28.54694],
  },
  {
    "country": "Namibia",
    "countryCode": "NA",
    "long": -22.95764,
    "lat": 18.49041,
    "bounds": [11.73333, -28.960831, 25.265751, -16.968889],
  },
  {
    "country": "Nepal",
    "countryCode": "NP",
    "long": 28.394857,
    "lat": 84.124008,
    "bounds": [80.055801, 26.347771, 88.195801, 30.41971],
  },
  {
    "country": "Netherlands",
    "countryCode": "NL",
    "long": 52.132633,
    "lat": 5.291266,
    "bounds": [3.363889, 50.756069, 7.209444, 53.509299],
  },
  {
    "country": "Netherlands Antilles",
    "countryCode": "AN",
    "long": 12.226079,
    "lat": -69.060087,
    "bounds": [-70.284912, 12.033213, -68.499046, 12.863099],
  },
  {
    "country": "New Caledonia",
    "countryCode": "NC",
    "long": -20.904305,
    "lat": 165.618042,
    "bounds": [163.992203, -22.39473, 168.146103, -20.087231],
  },
  {
    "country": "New Zealand",
    "countryCode": "NZ",
    "long": -40.900557,
    "lat": 174.885971,
    "bounds": [165.566357, -50.85445, 178.559693, -33.069679],
  },
  {
    "country": "Nicaragua",
    "countryCode": "NI",
    "long": 12.865416,
    "lat": -85.207229,
    "bounds": [-87.690292, 10.70972, -83.130867, 15.02472],
  },
  {
    "country": "Niger",
    "countryCode": "NE",
    "long": 17.607789,
    "lat": 8.081666,
    "bounds": [0.166667, 11.69169, 15.99666, 23.52232],
  },
  {
    "country": "Nigeria",
    "countryCode": "NG",
    "long": 9.081999,
    "lat": 8.675277,
    "bounds": [2.682129, 4.281943, 14.67416, 13.89313],
  },
  {
    "country": "Northern Mariana Islands",
    "countryCode": "MP",
    "long": 17.33083,
    "lat": 145.38469,
    "bounds": [144.623001, 13.2375, 144.953293, 13.63472],
  },
  {
    "country": "Norway",
    "countryCode": "NO",
    "long": 60.472024,
    "lat": 8.468946,
    "bounds": [-9.120001, 57.996101, 33.624989, 80.513313],
  },
  {
    "country": "Oman",
    "countryCode": "OM",
    "long": 21.512583,
    "lat": 55.923255,
    "bounds": [51.904148, 16.64386, 59.836101, 25.039221],
  },
  {
    "country": "Pakistan",
    "countryCode": "PK",
    "long": 30.375321,
    "lat": 69.345116,
    "bounds": [60.866871, 23.688049, 77.831413, 37.062759],
  },
  {
    "country": "Panama",
    "countryCode": "PA",
    "long": 8.537981,
    "lat": -80.782127,
    "bounds": [-83.031677, 7.205832, -77.146668, 9.620277],
  },
  {
    "country": "Papua New Guinea",
    "countryCode": "PG",
    "long": -6.314993,
    "lat": 143.95555,
    "bounds": [140.849899, -11.62028, 153.776306, -1.950556],
  },
  {
    "country": "Paraguay",
    "countryCode": "PY",
    "long": -23.442503,
    "lat": -58.443832,
    "bounds": [-62.63866, -27.586121, -54.243061, -19.296671],
  },
  {
    "country": "Peru",
    "countryCode": "PE",
    "long": -9.189967,
    "lat": -75.015152,
    "bounds": [-81.3564, -18.34799, -68.6875, -0.0375],
  },
  {
    "country": "Philippines",
    "countryCode": "PH",
    "long": 12.879721,
    "lat": 121.774017,
    "bounds": [117.1744, 5.051388, 126.598, 18.647499],
  },
  {
    "country": "Poland",
    "countryCode": "PL",
    "long": 51.919438,
    "lat": 19.145136,
    "bounds": [14.13333, 49.006351, 24.14471, 54.836651],
  },
  {
    "country": "Portugal",
    "countryCode": "PT",
    "long": 39.399872,
    "lat": -8.224454,
    "bounds": [-28.54945, 32.637489, -6.194444, 42.15221],
  },
  {
    "country": "Puerto Rico",
    "countryCode": "PR",
    "long": 18.220833,
    "lat": -66.590149,
    "bounds": [-67.25917, 17.928049, -65.625839, 18.511391],
  },
  {
    "country": "Qatar",
    "countryCode": "QA",
    "long": 25.354826,
    "lat": 51.183884,
    "bounds": [50.752491, 24.55559, 51.61166, 26.152769],
  },
  {
    "country": "Reunion",
    "countryCode": "RE",
    "long": -21.115141,
    "lat": 55.536384,
    "bounds": [55.221371, -21.373619, 55.85138, -20.86556],
  },
  {
    "country": "Romania",
    "countryCode": "RO",
    "long": 45.943161,
    "lat": 24.96676,
    "bounds": [20.261141, 43.624699, 29.701111, 48.253319],
  },
  {
    "country": "Russian Fed.",
    "countryCode": "RU",
    "long": 61.52401,
    "lat": 105.318756,
    "bounds": [26.970313, 41.196098, 180, 83.271366],
  },
  {
    "country": "Rwanda",
    "countryCode": "RW",
    "long": -1.940278,
    "lat": 29.873888,
    "bounds": [28.853319, -2.826667, 30.876381, -1.061622],
  },
  {
    "country": "Saint Helena",
    "countryCode": "SH",
    "long": -24.143474,
    "lat": -10.030696,
    "bounds": [-5.793568, -16.021259, -5.643567, -15.892676],
  },
  {
    "country": "Samoa",
    "countryCode": "WS",
    "long": -13.759029,
    "lat": -172.104629,
    "bounds": [-172.774994, -14.05306, -171.436707, -13.46056],
  },
  {
    "country": "San Marino",
    "countryCode": "SM",
    "long": 43.94236,
    "lat": 12.457777,
    "bounds": [12.405131, 43.894595, 12.508419, 43.988895],
  },
  {
    "country": "Sao Tome And Principe",
    "countryCode": "ST",
    "long": 0.18636,
    "lat": 6.613081,
    "bounds": [6.464999, 0.02, 7.4642, 1.7019],
  },
  {
    "country": "Saudi Arabia",
    "countryCode": "SA",
    "long": 23.885942,
    "lat": 45.079162,
    "bounds": [34.603039, 15.10555, 56.51305, 32.237339],
  },
  {
    "country": "Senegal",
    "countryCode": "SN",
    "long": 14.497401,
    "lat": -14.452362,
    "bounds": [-17.52, 12.30139, -11.35833, 16.69305],
  },
  {
    "country": "Serbia",
    "countryCode": "RS",
    "long": 44.016521,
    "lat": 21.005859,
    "bounds": [18.8281, 42.232201, 23.006941, 46.174992],
  },
  {
    "country": "Seychelles",
    "countryCode": "SC",
    "long": -4.679574,
    "lat": 55.491977,
    "bounds": [55.374699, -4.7892, 55.792801, -4.28],
  },
  {
    "country": "Sierra Leone",
    "countryCode": "SL",
    "long": 8.460555,
    "lat": -11.779889,
    "bounds": [-13.298431, 6.913198, -10.26652, 9.997496],
  },
  {
    "country": "Singapore",
    "countryCode": "SG",
    "long": 1.352083,
    "lat": 103.819836,
    "bounds": [103.648002, 1.259722, 103.998001, 1.425287],
  },
  {
    "country": "Slovakia",
    "countryCode": "SK",
    "long": 48.669026,
    "lat": 19.699024,
    "bounds": [16.84083, 47.740822, 22.558001, 49.605259],
  },
  {
    "country": "Slovenia",
    "countryCode": "SI",
    "long": 46.151241,
    "lat": 14.995463,
    "bounds": [13.38305, 45.425598, 16.60924, 46.872479],
  },
  {
    "country": "Solomon Islands",
    "countryCode": "SB",
    "long": -9.64571,
    "lat": 160.156194,
    "bounds": [154.5336, -11.84584, 162.386597, -5.018613],
  },
  {
    "country": "Somalia",
    "countryCode": "SO",
    "long": 5.152149,
    "lat": 46.199616,
    "bounds": [40.986309, -1.674361, 54.5336, 12.71278],
  },
  {
    "country": "South Africa",
    "countryCode": "ZA",
    "long": -30.559482,
    "lat": 22.937506,
    "bounds": [16.469721, -34.814999, 32.893089, -22.13611],
  },
  {
    "country": "South Georgia and the South Sandwich Isl",
    "countryCode": "GS",
    "long": -54.429579,
    "lat": -36.587909,
    "bounds": [-38.0289, -54.889721, -35.782791, -54.00333],
  },
  {
    "country": "Spain",
    "countryCode": "ES",
    "long": 40.463667,
    "lat": -3.74922,
    "bounds": [-17.99501, 27.73472, 4.336666, 43.774429],
  },
  {
    "country": "Sri Lanka",
    "countryCode": "LK",
    "long": 7.873054,
    "lat": 80.771797,
    "bounds": [79.698303, 5.943609, 81.890533, 9.826109],
  },
  {
    "country": "Sudan",
    "countryCode": "SD",
    "long": 12.862807,
    "lat": 30.217636,
    "bounds": [21.828251, 3.49214, 38.621319, 23.128],
  },
  {
    "country": "Suriname",
    "countryCode": "SR",
    "long": 3.919305,
    "lat": -56.027783,
    "bounds": [-58.071671, 1.849444, -53.98444, 5.997499],
  },
  {
    "country": "Swaziland",
    "countryCode": "SZ",
    "long": -26.522503,
    "lat": 31.465866,
    "bounds": [30.80222, -27.31667, 32.13422, -25.72834],
  },
  {
    "country": "Sweden",
    "countryCode": "SE",
    "long": 60.128161,
    "lat": 18.643501,
    "bounds": [11.10694, 55.001099, 24.16707, 69.063141],
  },
  {
    "country": "Switzerland",
    "countryCode": "CH",
    "long": 46.818188,
    "lat": 8.227512,
    "bounds": [5.965833, 45.823879, 10.48861, 47.80637],
  },
  {
    "country": "Syria",
    "countryCode": "SY",
    "long": 34.802075,
    "lat": 38.996815,
    "bounds": [35.590542, 32.313599, 42.373852, 37.322182],
  },
  {
    "country": "Taiwan",
    "countryCode": "TW",
    "long": 23.69781,
    "lat": 120.960515,
    "bounds": [120.053299, 21.903049, 121.999199, 25.284161],
  },
  {
    "country": "Tajikistan",
    "countryCode": "TJ",
    "long": 38.861034,
    "lat": 71.276093,
    "bounds": [67.3647, 36.672211, 75.18747, 41.050301],
  },
  {
    "country": "Tanzania",
    "countryCode": "TZ",
    "long": -6.369028,
    "lat": 34.888822,
    "bounds": [29.59055, -11.72695, 40.45332, -0.997581],
  },
  {
    "country": "Thailand",
    "countryCode": "TH",
    "long": 15.870032,
    "lat": 100.992541,
    "bounds": [97.34581, 5.631942, 105.634399, 20.455271],
  },
  {
    "country": "Togo",
    "countryCode": "TG",
    "long": 8.619543,
    "lat": 0.824782,
    "bounds": [-0.150955, 6.099102, 1.799956, 11.13927],
  },
  {
    "country": "Tonga",
    "countryCode": "TO",
    "long": -21.178986,
    "lat": -175.198242,
    "bounds": [-175.360596, -21.454201, -173.906097, -18.5681],
  },
  {
    "country": "Trinidad And Tobago",
    "countryCode": "TT",
    "long": 10.691803,
    "lat": -61.222503,
    "bounds": [-61.92445, 10.03805, -60.928341, 10.83861],
  },
  {
    "country": "Tunisia",
    "countryCode": "TN",
    "long": 33.886917,
    "lat": 9.537499,
    "bounds": [7.491666, 30.236059, 11.58333, 37.338039],
  },
  {
    "country": "Turkey",
    "countryCode": "TR",
    "long": 38.963745,
    "lat": 35.243322,
    "bounds": [26.03331, 35.821659, 44.82193, 42.107208],
  },
  {
    "country": "Turkmenistan",
    "countryCode": "TM",
    "long": 38.969719,
    "lat": 59.556278,
    "bounds": [52.443321, 35.145538, 66.672501, 42.797798],
  },
  {
    "country": "Turks and Caicos Islands",
    "countryCode": "TC",
    "long": 21.694025,
    "lat": -71.797928,
    "bounds": [-73.706124, 20.91527, -73.002792, 21.332491],
  },
  {
    "country": "Tuvalu",
    "countryCode": "TV",
    "long": -7.109535,
    "lat": 177.64933,
    "bounds": [178.842178, -8.128861, 179.383103, -7.568621],
  },
  {
    "country": "Uganda",
    "countryCode": "UG",
    "long": 1.373333,
    "lat": 32.290275,
    "bounds": [29.578329, -1.464444, 35.026089, 4.22176],
  },
  {
    "country": "Ukraine",
    "countryCode": "UA",
    "long": 48.379433,
    "lat": 31.16558,
    "bounds": [22.151449, 44.39526, 40.179699, 52.3797],
  },
  {
    "country": "United Arab Emirates",
    "countryCode": "AE",
    "long": 23.424076,
    "lat": 53.847818,
    "bounds": [50.826099, 22.057159, 56.37582, 26.083599],
  },
  {
    "country": "United Kingdom",
    "countryCode": "GB",
    "long": 55.378051,
    "lat": -3.435973,
    "bounds": [-8.164723, 49.955269, 1.7425, 60.6311],
  },
  {
    "country": "United States",
    "countryCode": "US",
    "long": 37.09024,
    "lat": -95.712891,
    "bounds": [-125.3321, 23.8991, -65.7421, 49.4325],
  },
  {
    "country": "Uruguay",
    "countryCode": "UY",
    "long": -32.522779,
    "lat": -55.765835,
    "bounds": [-58.438621, -34.977779, -53.095001, -30.100559],
  },
  {
    "country": "Uzbekistan",
    "countryCode": "UZ",
    "long": 41.377491,
    "lat": 64.585262,
    "bounds": [55.9972, 37.185261, 73.172997, 45.57066],
  },
  {
    "country": "Vanuatu",
    "countryCode": "VU",
    "long": -15.376706,
    "lat": 166.959158,
    "bounds": [165.777496, -19.64028, 169.483902, -10.67167],
  },
  {
    "country": "Venezuela",
    "countryCode": "VE",
    "long": 6.42375,
    "lat": -66.58973,
    "bounds": [-73.383903, 0.649722, -59.801392, 12.19528],
  },
  {
    "country": "Viet Nam",
    "countryCode": "VN",
    "long": 14.058324,
    "lat": 108.277199,
    "bounds": [102.140503, 8.574163, 109.459099, 23.37582],
  },
  {
    "country": "Yemen",
    "countryCode": "YE",
    "long": 15.552727,
    "lat": 48.516388,
    "bounds": [42.61388, 12.59972, 53.078251, 18.99888],
  },
  {
    "country": "Zambia",
    "countryCode": "ZM",
    "long": -13.133897,
    "lat": 27.849332,
    "bounds": [21.998329, -18.075279, 33.693039, -8.220806],
  },
  {
    "country": "Zimbabwe",
    "countryCode": "ZW",
    "long": -19.015438,
    "lat": 29.154857,
    "bounds": [25.23666, -22.41614, 33.07304, -15.61611],
  },
];

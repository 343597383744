import { injectScript } from '~/utils/dom';

var facebookAuth: Types.FacebookAuth;
// var loading: Promise<Types.FacebookAuth>;

const SCRIPT_ID = "fb-script";
const SCRIPT_SRC = "https://connect.facebook.net/en_US/sdk.js";

function _loadSDK (appId: string): Promise<HTMLScriptElement> {
  return new Promise((resolve, reject) => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v10.0',
      });
    };
    const el = injectScript(SCRIPT_ID, SCRIPT_SRC);
    el.setAttribute("crossorigin", "anonymous");
    el.onload = () => {
      resolve(el);
    };
  });
}

export function loadFacebookAuth (appId: string): Promise<Types.FacebookAuth> {
  if (facebookAuth) {
    return Promise.resolve(facebookAuth);
  }
  return _loadSDK(appId).then((el) => {
    // It is required to create a new Promise because of the different return types
    return new Promise((resolve, reject) => {
      const loginCb = (res: Types.LoginResponse) => {
        if (res.status !== "connected") {
          return reject(res.status);
        }
        const auth = res.authResponse;
        if (auth?.accessToken && auth?.userID) {
          facebookAuth = auth;
          resolve(facebookAuth);
        } else {
          reject(new Error("Missing facebook login info"));
        }
      };
      window.FB.login(loginCb, { scope: "email" });
    });
  });
}

export function getProfile (): Promise<Types.FacebookUser> {
  return new Promise((resolve) => {
    window.FB.api("/me", { fields: 'first_name,last_name,email' }, resolve);
  });
}

export async function signOutFacebookSDK (appId: string) {
  if (!window.FB) {
    await _loadSDK(appId);
  }
}

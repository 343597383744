/**
 * Count identical caracters in two given words
 * until one character from baseWord is not in targetWord,
 * which gives a kind of matching score between 2 words.
 *
 * This can be used to filter or sort entries based on similarity.
 *
 * @param {String} baseWord - word to search with
 * @param {String} targetWord - word to search against
 * @returns {Number} similarity score
 */
export function wordSimilarity (baseWord, targetWord) {
  var bi = 0; var ti = 0; var lastMatchingIndex;
  // increase score (bi) for each caracter of baseWord that is in target word
  while (bi < baseWord.length && ti < targetWord.length) {
    if (baseWord[bi] === targetWord[ti]) {
      bi++;
      lastMatchingIndex = bi;
    }
    ti++;
  }
  // if one character of baseWord is not at all in targetWord return 0
  // which is true when we reach the end of target word and last char was not in
  if (ti === targetWord.length && baseWord.length > lastMatchingIndex) return 0;
  return bi;
}

/**
 * Check if `word` is similar to `search` (case insensitive).
 *
 * @param {String} search - searched term
 * @param {String} word - word to compare search against
 * @returns {Boolean} `search` is similar to `word`
 */
export function wordMatches (search, word) {
  if (!search) return true;

  const baseWord = String(search).toUpperCase();
  const targetWord = String(word).toUpperCase();
  return wordSimilarity(baseWord, targetWord) > 0;
}

/**
 * Check if any of `words` are similar to `search` (case insensitive).
 *
 * @param {String} search - searched term
 * @param {String[]} words - words to compare search against
 * @returns {Boolean} `search` is similar to at least one of `words`
 */
export function anyWordMatches (search, words) {
  return words.some(w => wordMatches(search, w));
}





































import { Vue, Component, Prop } from 'vue-property-decorator';

import MapOutline from '~/components/icons/MapOutline.vue';
import GlobalSearchFilteredRoute from '~/components/lists/items/GlobalSearchFilteredRoute.vue';

@Component({
  components: { MapOutline, GlobalSearchFilteredRoute },
})
export default class GlobalSearchRoutes extends Vue {
  @Prop(String) searchText: '';
  @Prop(Array) routes: [];
  @Prop() suggested: Types.mappingOfSuggested[];

  isLoading: boolean = false;
}

import { Middleware } from '@nuxt/types';

const redirectionMiddleware: Middleware = ({ store, route, redirect }) => {
  console.info('[redirectionMiddleware]');
  const locale = store.state.lang.locale;

  /*
    Due to the nuxt-18n config (strategy: 'prefix_and_default'),
    adding a '/' at the end of the route causes routing crashes. So we remove it.
  */
  if (route.path.endsWith('/') && route.path.includes(`/${locale}/`)) {
    const { path, query, hash } = route;
    const nextPath = path.replace(/\/+$/, '') || '/';
    const nextRoute = { path: nextPath, query, hash };

    redirect(nextRoute);
  }

  /*
    In explorer view, we wanted to have custom redirections for non-existing
    URLs. This method will allow a redirect based on provided argument
  */
  const explorerRedirection = (query: string) => {
    if (
      route.path === `/${locale}/explorer/${query}` ||
      route.path === `/${locale}/explorer/${query}/` ||
      route.path === `/explorer/${query}` ||
      route.path === `/explorer/${query}/`
    ) {
      redirect({ name: `explorer___${locale}${locale === 'fr' ? '___default' : ''}`, query: { tab: query } });
    }
  };

  explorerRedirection('destinations');
  explorerRedirection('routes');
  explorerRedirection('events');

  /*
    This logic will ensure i18n to return the right language, if cookie value
    doesn't match user selection or i18n config locale.
  */
  const routeName: any = route?.name;
  if (routeName) {
    const routeLocale: string = routeName?.includes('___default') ? routeName?.match(/___(.*)___/)[1] : routeName.match(/___(.*)/)[1];

    if (routeLocale && (routeLocale !== locale)) {
      store.commit('lang/SET_LANG', routeLocale);
      const label: string = store.state.lang.locales.find((locale: Types.Locale) => locale.code === routeLocale).name;
      store.commit('lang/SET_LABEL', label);
      store.commit('explorer/SET_SPORTS', []);
    }
  }
};

export default redirectionMiddleware;

import { footSports, snowSports, bikeSports } from '~/utils/runConstants.js';

export const receptionCenters = [
  {
    id: '0',
    name: 'Vallouise-Pelvoux',
    picture: '/img/sign/img__rossignol__bike2.png',
    address: 'Chemin des Chambonnettes',
    zipCode: '05290',
    city: 'Vallouise-Pelvoux',
    email: 'vallouise@gmail.com',
    phone: '+33492233026',
  },
  {
    id: '1',
    name: 'Freissinières',
    picture: '/img/sign/img__rossignol__bike2.png',
    address: 'Chemin des Chambonnettes',
    zipCode: '05290',
    city: 'Vallouise-Pelvoux',
    email: 'vallouise@gmail.com',
    phone: '+33492233026',
  },
  {
    id: '2',
    name: 'Saint Martin de Queryières',
    picture: '/img/sign/img__rossignol__bike2.png',
    address: 'Chemin des Chambonnettes',
    zipCode: '05290',
    city: 'Vallouise-Pelvoux',
    email: 'vallouise@gmail.com',
    phone: '+33492233026',
  },
  {
    id: '3',
    name: 'Les Vigneaux',
    picture: '/img/sign/img__rossignol__bike2.png',
    address: 'Chemin des Chambonnettes',
    zipCode: '05290',
    city: 'Vallouise-Pelvoux',
    email: 'vallouise@gmail.com',
    phone: '+33492233026',
  },
];

export const providers = [
  {
    id: '0',
    name: 'La ferme à Dédé du pays',
    link: '/',
    type: 'FOOD',
    respects: true,
    photo: '/img/sign/img__rossignol__bike2.png',
    location: 'Grenoble',
    coordinates: [45.413220, -1.18],
    description: 'Le concept des restaurants de "La ferme à Dédé" à Grenoble et Sassenage est un retour à la tradition régionale. La décoration est authentique et chaleureuse et il y a toujours un détail pour vous rappeler le charme d\'antan.',
    phone: '+3349223030338',
    destinationId: '0',
  },
  {
    id: '1',
    name: 'Pays du Vélo',
    link: '/',
    type: 'SHOP',
    isRossignol: true,
    photo: '/img/sign/img__rossignol__bike2.png',
    location: 'Grenoble',
    coordinates: [45.413220, -1.18],
    description: 'Le concept des restaurants de "La ferme à Dédé" à Grenoble et Sassenage est un retour à la tradition régionale. La décoration est authentique et chaleureuse et il y a toujours un détail pour vous rappeler le charme d\'antan.',
    phone: '+3349223030338',
    destinationId: '0',
  },
  {
    id: '2',
    name: 'Camping des Ecrins',
    link: '/',
    type: 'FOOD',
    photo: '/img/sign/img__rossignol__bike2.png',
    location: 'La Meije',
    coordinates: [45.413220, -1.18],
    description: 'Le concept des restaurants de "La ferme à Dédé" à Grenoble et Sassenage est un retour à la tradition régionale. La décoration est authentique et chaleureuse et il y a toujours un détail pour vous rappeler le charme d\'antan.',
    phone: '+3349223030338',
    destinationId: '0',
  },
  {
    id: '3',
    name: 'Musée du parapente',
    link: '/',
    type: 'SHOP',
    photo: '/img/sign/img__rossignol__bike2.png',
    location: 'Chartreuse',
    coordinates: [45.413220, -1.18],
    description: 'Le concept des restaurants de "La ferme à Dédé" à Grenoble et Sassenage est un retour à la tradition régionale. La décoration est authentique et chaleureuse et il y a toujours un détail pour vous rappeler le charme d\'antan.',
    phone: '+3349223030338',
    destinationId: '0',
  },
];

export const blogNews = [
  {
    id: '0',
    category: 'sport',
    author: 'Rossignol Team',
    title: 'The outdoor experience app is here!',
    date: new Date(),
    description: 'Come discover the new experience around outdoor activities ! Track you activities and discover many routes and runs, Nullam id dolor id nibh ultricies vehicula ut id elit, Nullam id dolor id nibh ultricies vehicula ut id eli',
    tags: [footSports[1]],
    photo: '/img/home/img__home__inspiration3.png',
    link: '/',
    alt: 'Sport',
  },
  {
    id: '1',
    category: 'sport',
    author: 'Rossignol Team',
    title: 'How to take care of the board',
    date: new Date(Date.now() + (3600 * 1000 * 24)),
    description: 'Come discover the new experience around outdoor activities ! Track you activities and discover many routes and runs, Nullam id dolor id nibh ultricies vehicula ut id elit, Nullam id dolor id nibh ultricies vehicula ut id eli',
    tags: [footSports[1], snowSports[1]],
    photo: '/img/home/img__home__inspiration3.png',
    link: '/',
    alt: 'Sport',
  },
  {
    id: '2',
    category: 'tips',
    author: 'Rossignol Team',
    title: 'Top 10 ski stations for touring',
    date: new Date(Date.now() + (3600 * 1000 * 48)),
    description: 'Come discover the new experience around outdoor activities ! Track you activities and discover many routes and runs, Nullam id dolor id nibh ultricies vehicula ut id elit, Nullam id dolor id nibh ultricies vehicula ut id eli',
    tags: [bikeSports[1], bikeSports[0], snowSports[0]],
    photo: '/img/BJ_Rossiteamshoot.png',
    link: '/',
    alt: 'Tips',
  },
  {
    id: '3',
    category: 'sport',
    author: 'Rossignol Team',
    title: 'How to run in the snow',
    date: new Date(Date.now() + (3600 * 1000 * 72)),
    description: 'Come discover the new experience around outdoor activities ! Track you activities and discover many routes and runs, Nullam id dolor id nibh ultricies vehicula ut id elit, Nullam id dolor id nibh ultricies vehicula ut id eli',
    tags: [footSports[0]],
    photo: '/img/BJ_Rossiteamshoot.png',
    link: '/',
    alt: 'Sport',
  },
  {
    id: '4',
    category: 'tips',
    author: 'Rossignol Team',
    title: 'What are the outdoor days?',
    date: new Date(Date.now() + (3600 * 1000 * 94)),
    description: 'Come discover the new experience around outdoor activities ! Track you activities and discover many routes and runs, Nullam id dolor id nibh ultricies vehicula ut id elit, Nullam id dolor id nibh ultricies vehicula ut id eli',
    tags: [footSports[0]],
    photo: '/img/home/img__home__inspiration4.png',
    link: '/',
    alt: '',
  },
  {
    id: '5',
    category: 'sport',
    author: 'Rossignol Team',
    title: 'Topic that will never appear',
    date: new Date(Date.now() + (3600 * 1000 * 116)),
    description: 'Come discover the new experience around outdoor activities ! Track you activities and discover many routes and runs, Nullam id dolor id nibh ultricies vehicula ut id elit, Nullam id dolor id nibh ultricies vehicula ut id eli',
    tags: [footSports[0]],
    photo: '/img/home/img__home__inspiration4.png',
    link: '/',
    alt: '',
  },
];

export const eventNews = [
  {
    id: '0',
    category: 'Tips',
    title: 'Run in the summer, snow in the winter',
    startDate: new Date(Date.now() - 86400000),
    endDate: new Date(Date.now() - 16400000),
    description: 'Come discover the new experience around outdoor activities !',
    location: 'Chartreuse',
    photo: '/img/home/img__home__inspiration4.png',
    link: '/',
    sports: [footSports[0], snowSports[0]],
    alt: 'Tips',
    coordinates: [45.413220, -1.2],
    facebookPage: 'https://www.google.fr/',
  },
  {
    id: '1',
    category: 'Sports',
    title: 'Run in the summer, snow in the winter',
    startDate: new Date(Date.now() + (3600 * 1000 * 24)),
    endDate: new Date(Date.now() + (3600 * 1000 * 48)),
    description: 'Come discover the new experience around outdoor activities !',
    location: 'Chartreuse',
    photo: '/img/home/img__home__inspiration4.png',
    link: '/',
    sports: [bikeSports[0]],
    alt: 'Sports',
    coordinates: [45.413220, -1.2],
    facebookPage: 'https://www.google.fr/',
  },
  {
    id: '2',
    category: 'Sports',
    title: 'Run in the summer, snow in the winter',
    startDate: new Date(Date.now() + (3600 * 1000 * 48)),
    endDate: new Date(Date.now() + (3600 * 1000 * 72)),
    description: 'Come discover the new experience around outdoor activities !',
    location: 'Chartreuse',
    photo: '/img/home/img__home__inspiration4.png',
    link: '/',
    sports: [bikeSports[0]],
    alt: '',
    coordinates: [45.413220, -1.2],
  },
  {
    id: '3',
    category: 'Sports',
    title: 'Run in the summer, snow in the winter',
    startDate: new Date(Date.now() + (3600 * 1000 * 72)),
    endDate: new Date(Date.now() + (3600 * 1000 * 73)),
    description: 'Come discover the new experience around outdoor activities !',
    location: 'Chartreuse',
    photo: '/img/home/img__home__inspiration4.png',
    link: '/',
    sports: [bikeSports[0], footSports[1], snowSports[1]],
    alt: 'Sports',
    coordinates: [45.413220, -1.2],
  },
  {
    id: '4',
    category: 'Sports',
    title: 'THE OLDEST Run in the summer, snow in the winter',
    startDate: new Date(Date.now() + (3600 * 1000 * 80)),
    endDate: new Date(Date.now() + (3600 * 1000 * 82)),
    description: 'Come discover the new experience around outdoor activities !',
    location: 'Chartreuse',
    photo: '/img/home/img__home__inspiration4.png',
    link: '/',
    sports: [bikeSports[0]],
    alt: 'Run in the summer',
    coordinates: [45.413220, -1.18],
  },
];

export const destinations = [
  {
    id: '0',
    title: 'Col du corbier Haut-Chabais',
    location: ['Haute-Savoie'],
    photos: [
      '/img/home/img__rossignol__trail2.png',
      '/img/home/img__home__inspiration3.png',
      '/img/home/img__home__inspiration4.png',
      '/img/sign/img__rossignol__bike1.png',
      '/img/sign/img__rossignol__bike2.png',
      '/img/sign/img__rossignol__mtb1.png',
    ],
    sports: [
      {
        id: '0',
        sport: Object.assign({}, bikeSports[1]),
        routes: [
          {
            id: '0',
            destinationId: '0',
            name: 'Les Tenailles',
            label: {
              difficulty: 'EASY',
              electricalDifficulty: 'EASY',
              name: '1',
            },
            link: '/',
            type: 'Route',
            location: 'Saint Pierre de Chartreuse',
            startPoint: 'Saint Pierre de Chartreuse',
            endPoint: 'Saint Pierre de Chartreuse',
            distance: 21000,
            elevationPlus: 2000,
            elevationMinus: 1000,
            altMax: 1500,
            pictures: [
              '/img/home/img__rossignol__trail2.png',
              '/img/home/img__rossignol__trail2.png',
              '/img/home/img__rossignol__trail2.png',
              '/img/home/img__rossignol__trail2.png',
              '/img/home/img__rossignol__trail2.png',
              '/img/home/img__rossignol__trail2.png',
              '/img/home/img__rossignol__trail2.png',
            ],
            ratings: [
              {
                id: '0',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 5,
                date: new Date(),
                activityDate: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '1',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 4,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '2',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 2,
                date: new Date(),
                activityDate: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '3',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 1,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
            ],
            path: [[45.334852, -1.509485], [47.342596, -1.328731], [47.241487, -1.190568], [47.234787, -1.358337]],
            isClosed: true,
            closedStatus: 'Snowy and wet.',
            sport: 'MOUNTAIN_BIKE',
            latestTimes: [
              {
                id: '0',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                date: new Date(),
                timeRealized: new Date(),
              },
              {
                id: '1',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                date: new Date(),
                timeRealized: new Date(),
              },
              {
                id: '2',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                date: new Date(),
                timeRealized: new Date(),
              },
              {
                id: '3',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                date: new Date(),
                timeRealized: new Date(),
              },
              {
                id: '4',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                date: new Date(),
                timeRealized: new Date(),
              },
              {
                id: '5',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                date: new Date(),
                timeRealized: new Date(),
              },
              {
                id: '6',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                date: new Date(),
                timeRealized: new Date(),
              },
              {
                id: '7',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                date: new Date(),
                timeRealized: new Date(),
              },
              {
                id: '8',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                date: new Date(),
                timeRealized: new Date(),
              },
            ],
          },
          {
            id: '1',
            destinationId: '0',
            name: 'Les Tenailles',
            label: {
              difficulty: 'NORMAL',
              electricalDifficulty: 'EASY',
              name: '1',
            },
            link: '/',
            type: 'Route',
            location: 'Saint Pierre de Chartreuse',
            startPoint: 'Saint Pierre de Chartreuse',
            endPoint: 'Collet d\'Allevard',
            distance: 21000,
            elevationPlus: 2000,
            elevationMinus: 1000,
            altMax: 1500,
            pictures: [
              '/img/home/img__rossignol__trail2.png',
            ],
            ratings: [
              {
                id: '0',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 2,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '1',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 2,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '2',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 2,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '3',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 2,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
            ],
            path: [[45.334852, -1.509485], [47.342596, -1.328731], [47.241487, -1.190568], [47.234787, -1.358337]],
            sport: 'MOUNTAIN_BIKE',
          },
          {
            id: '2',
            destinationId: '0',
            name: 'Les Tenailles',
            label: {
              difficulty: 'HARD',
              electricalDifficulty: 'NORMAL',
              name: '12',
            },
            link: '/',
            type: 'Route',
            location: 'Saint Pierre de Chartreuse',
            startPoint: 'Saint Pierre de Chartreuse',
            endPoint: 'Saint Pierre de Chartreuse',
            distance: 21000,
            elevationPlus: 2000,
            elevationMinus: 1000,
            altMax: 1500,
            pictures: [
              '/img/home/img__rossignol__trail2.png',
            ],
            ratings: [
              {
                id: '0',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 2,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '1',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 4,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '2',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 1,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '3',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 5,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
            ],
            path: [[45.334852, -1.509485], [47.342596, -1.328731], [47.241487, -1.190568], [47.234787, -1.358337]],
            sport: 'MOUNTAIN_BIKE',
          },
          {
            id: '3',
            destinationId: '0',
            name: 'Les Tenailles',
            label: {
              difficulty: 'VERY_HARD',
              electricalDifficulty: 'HARD',
              name: '12',
            },
            link: '/',
            type: 'Route',
            location: 'Saint Pierre de Chartreuse',
            startPoint: 'Saint Pierre de Chartreuse',
            endPoint: 'Sappey-en-Chartreuse',
            distance: 21000,
            elevationPlus: 2000,
            elevationMinus: 1000,
            altMax: 1500,
            pictures: [
              '/img/home/img__rossignol__trail2.png',
              '/img/home/img__rossignol__trail2.png',
            ],
            ratings: [
              {
                id: '0',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 2,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '1',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 4,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '2',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 1,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '3',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 5,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
            ],
            path: [[45.334852, -1.509485], [47.342596, -1.328731], [47.241487, -1.190568], [47.234787, -1.358337]],
            sport: 'MOUNTAIN_BIKE',
          },
          {
            id: '4',
            destinationId: '0',
            name: 'Les Tenailles',
            label: {
              difficulty: 'VERY_HARD',
              electricalDifficulty: 'VERY_HARD',
              name: '12',
            },
            link: '/',
            type: 'Route',
            location: 'Saint Pierre de Chartreuse',
            startPoint: 'Saint Pierre de Chartreuse',
            endPoint: 'Saint Pierre de Chartreuse',
            distance: 21000,
            elevationPlus: 2000,
            elevationMinus: 1000,
            altMax: 1500,
            pictures: [
              '/img/home/img__rossignol__trail2.png',
              '/img/home/img__rossignol__trail2.png',
            ],
            ratings: [
              {
                id: '0',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 2,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '1',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 4,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '2',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 1,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '3',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 5,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
            ],
            path: [[45.334852, -1.509485], [47.342596, -1.328731], [47.241487, -1.190568], [47.234787, -1.358337]],
            sport: 'MOUNTAIN_BIKE',
          },
          {
            id: '5',
            destinationId: '0',
            name: 'Les Tenailles',
            label: {
              difficulty: 'VERY_HARD',
              name: '12',
            },
            link: '/',
            type: 'Route',
            location: 'Saint Pierre de Chartreuse',
            startPoint: 'Saint Pierre de Chartreuse',
            endPoint: 'Saint Pierre de Chartreuse',
            distance: 21000,
            elevationPlus: 2000,
            elevationMinus: 1000,
            altMax: 1500,
            pictures: [
              '/img/home/img__rossignol__trail2.png',
              '/img/home/img__rossignol__trail2.png',
            ],
            ratings: [
              {
                id: '0',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 2,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '1',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 4,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '2',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 1,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '3',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 5,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
            ],
            path: [[45.334852, -1.509485], [47.342596, -1.328731], [47.241487, -1.190568], [47.234787, -1.358337]],
            sport: 'RUNNING',
          },
          {
            id: '6',
            destinationId: '0',
            name: 'Les Tenailles',
            label: {
              difficulty: 'VERY_HARD',
              name: '12',
            },
            link: '/',
            type: 'Route',
            location: 'Saint Pierre de Chartreuse',
            startPoint: 'Saint Pierre de Chartreuse',
            endPoint: 'Saint Pierre de Chartreuse',
            distance: 21000,
            elevationPlus: 2000,
            elevationMinus: 1000,
            altMax: 1500,
            pictures: [
              '/img/home/img__rossignol__trail2.png',
              '/img/home/img__rossignol__trail2.png',
            ],
            ratings: [
              {
                id: '0',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 2,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '1',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 4,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '2',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 1,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '3',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 5,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
            ],
            path: [[44.334852, -1.509485], [47.342596, -1.328731], [47.241487, -1.190568], [47.234787, -1.358337]],
            sport: 'RUNNING',
          },
          {
            id: '7',
            destinationId: '0',
            name: 'Les Tenailles',
            label: {
              difficulty: 'VERY_HARD',
              name: '12',
            },
            link: '/',
            type: 'Route',
            location: 'Saint Pierre de Chartreuse',
            startPoint: 'Saint Pierre de Chartreuse',
            endPoint: 'Saint Pierre de Chartreuse',
            distance: 21000,
            elevationPlus: 2000,
            elevationMinus: 1000,
            altMax: 1500,
            pictures: [
              '/img/home/img__rossignol__trail2.png',
              '/img/home/img__rossignol__trail2.png',
            ],
            ratings: [
              {
                id: '0',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 2,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '1',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 4,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '2',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 1,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
              {
                id: '3',
                author: 'John Doe',
                picture: 'https://rossignol-outdoorxp.rwigo.dev/media/0a04f1524dc5de4f9f44c338eba0272f9ea7be33.jpg',
                note: 5,
                date: new Date(),
                desc: 'Très beau parcours, Attention au moral en arrivant sur St Pierre, les balises GR sont parfois incohérentes .... A faire aussi pour les points de vue : Monastère, Petit Som et Grand Som.',
              },
            ],
            path: [[44.334852, -1.509485], [47.342596, -1.328731], [47.241487, -1.190568], [47.234787, -1.358337]],
            sport: 'RUNNING',
          },
        ],
      },
    ],
    alt: 'Col du Corbier',
    destinationType: 'Resort',
    landscapeType: 'coastline',
    zipCodes: ['73'],
    respects: true,
    coordinates: [45.413220, -1.2],
    todayWeather: 'SUNNY',
    tommorowWeather: 'RAIN',
    todayTemperature: 4,
    tommorowTemperature: 12,
    description: 'La destination montagne à ne pas louper cet hiver : ski de rando, trail sur neige, régalez vous ! Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
    partners: [
      '/img/home/logo__full__bike__monochrome.png',
      '/img/home/logo__full__nordicwalk__monochrome.png',
      '/img/home/logo__full__skitouring__monochrome.png',
      '/img/home/logo__full__trail__monochrome.png',
    ],
    events: [
      eventNews[0],
      eventNews[1],
      eventNews[2],
      eventNews[3],
      eventNews[4],
    ],
    providersList: [
      providers[0],
      providers[1],
      providers[2],
      providers[3],
    ],
    articles: [
      blogNews[0],
      blogNews[1],
      blogNews[2],
      blogNews[3],
    ],
  },
  {
    id: '1',
    title: 'Chartreuse',
    location: ['Isère', 'Savoie'],
    photos: [
      '/img/home/img__rossignol__trail2.png',
      '/img/home/img__home__inspiration3.png',
      '/img/home/img__home__inspiration4.png',
    ],
    sports: [
      { id: '0', sport: Object.assign({}, bikeSports[1]), routes: 25 },
      { id: '1', sport: Object.assign({}, footSports[0]), routes: 20 },
      { id: '2', sport: Object.assign({}, footSports[1]), routes: 25 },
      { id: '3', sport: Object.assign({}, snowSports[0]), routes: 12 },
      { id: '4', sport: Object.assign({}, snowSports[2]), routes: 34 },
    ],
    alt: 'Chartreuse',
    destinationType: 'FFC',
    landscapeType: 'coastline',
    zipCodes: ['38', '73'],
    respects: false,
    coordinates: [45.413220, -1.18],
    todayWeather: 'CLOUDY',
    tommorowWeather: 'SNOW',
    todayTemperature: 4,
    tommorowTemperature: 12,
    description: 'La destination montagne à ne pas louper cet hiver : ski de rando, trail sur neige, régalez vous ! Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
    partners: [
      '/img/home/logo__full__bike__monochrome.png',
      '/img/home/logo__full__nordicwalk__monochrome.png',
      '/img/home/logo__full__skitouring__monochrome.png',
      '/img/home/logo__full__trail__monochrome.png',
    ],
    events: [
      eventNews[0],
      eventNews[2],
      eventNews[4],
    ],
    providersList: [
      providers[2],
      providers[3],
    ],
    articles: [
      blogNews[0],
    ],
  },
  {
    id: '2',
    title: 'Oisans',
    location: ['Hautes-Alpes'],
    photos: [
      '/img/home/img__rossignol__trail2.png',
      '/img/home/img__home__inspiration3.png',
      '/img/home/img__home__inspiration4.png',
    ],
    sports: [
      { id: '1', sport: Object.assign({}, footSports[1]), routes: 25 },
      { id: '2', sport: Object.assign({}, footSports[2]), routes: 12 },
      { id: '3', sport: Object.assign({}, snowSports[0]), routes: 34 },
      { id: '4', sport: Object.assign({}, snowSports[2]), routes: 34 },
    ],
    alt: 'Oisans',
    destinationType: 'FFC',
    landscapeType: 'mountain',
    zipCodes: ['38'],
    respects: false,
    coordinates: [45.413220, -1.17],
    todayWeather: 'FOGGY',
    tommorowWeather: 'STORM',
    todayTemperature: 4,
    tommorowTemperature: 12,
    description: 'La destination montagne à ne pas louper cet hiver : ski de rando, trail sur neige, régalez vous ! Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
    partners: [
      '/img/home/logo__full__bike__monochrome.png',
      '/img/home/logo__full__nordicwalk__monochrome.png',
      '/img/home/logo__full__skitouring__monochrome.png',
      '/img/home/logo__full__trail__monochrome.png',
    ],
    events: [
      eventNews[0],
    ],
    providersList: [
      providers[0],
      providers[1],
    ],
    articles: [
      blogNews[0],
      blogNews[1],
      blogNews[3],
    ],
  },
  {
    id: '3',
    title: 'Mont Cuinat',
    location: ['Savoie'],
    photos: [
      '/img/home/img__rossignol__trail2.png',
      '/img/home/img__home__inspiration3.png',
      '/img/home/img__home__inspiration4.png',
    ],
    sports: [
      { id: '0', sport: Object.assign({}, bikeSports[1]), routes: 12 },
      { id: '1', sport: Object.assign({}, footSports[0]), routes: 2 },
      { id: '2', sport: Object.assign({}, footSports[1]), routes: 34 },
      { id: '3', sport: Object.assign({}, footSports[2]), routes: 23 },
    ],
    alt: 'Mont Cuinat',
    destinationType: 'Shop',
    landscapeType: 'urban',
    zipCodes: ['73'],
    respects: false,
    coordinates: [45.413220, 0],
    todayWeather: 'SUNNY',
    tommorowWeather: 'RAIN',
    todayTemperature: 4,
    tommorowTemperature: 12,
    description: 'La destination montagne à ne pas louper cet hiver : ski de rando, trail sur neige, régalez vous ! Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
    partners: [
      '/img/home/logo__full__bike__monochrome.png',
      '/img/home/logo__full__nordicwalk__monochrome.png',
      '/img/home/logo__full__skitouring__monochrome.png',
      '/img/home/logo__full__trail__monochrome.png',
    ],
    events: [
      eventNews[1],
      eventNews[2],
      eventNews[3],
      eventNews[4],
    ],
    providersList: [
      providers[0],
      providers[3],
    ],
    articles: [
      blogNews[1],
      blogNews[2],
    ],
  },
  {
    id: '4',
    title: 'Chaîne de Belledonne',
    location: ['Isère'],
    photos: [
      '/img/home/img__rossignol__trail2.png',
      '/img/home/img__home__inspiration3.png',
      '/img/home/img__home__inspiration4.png',
    ],
    sports: [
      { id: '0', sport: Object.assign({}, bikeSports[1]), routes: 12 },
      { id: '1', sport: Object.assign({}, footSports[0]), routes: 2 },
      { id: '2', sport: Object.assign({}, footSports[1]), routes: 34 },
      { id: '3', sport: Object.assign({}, footSports[2]), routes: 23 },
      { id: '4', sport: Object.assign({}, bikeSports[0]), routes: 14 },
    ],
    alt: 'Belledonne',
    destinationType: 'Resort',
    landscapeType: 'valley',
    zipCodes: ['38'],
    respects: true,
    coordinates: [40.413220, -1.17],
    todayWeather: 'SUNNY',
    tommorowWeather: 'RAIN',
    todayTemperature: 4,
    tommorowTemperature: 12,
    description: 'La destination montagne à ne pas louper cet hiver : ski de rando, trail sur neige, régalez vous ! Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
    partners: [
      '/img/home/logo__full__bike__monochrome.png',
      '/img/home/logo__full__nordicwalk__monochrome.png',
      '/img/home/logo__full__skitouring__monochrome.png',
      '/img/home/logo__full__trail__monochrome.png',
    ],
    events: [
      eventNews[3],
      eventNews[4],
    ],
    providersList: [
      providers[1],
      providers[3],
    ],
    articles: [
      blogNews[0],
      blogNews[1],
      blogNews[2],
      blogNews[3],
    ],
  },
];

export const searchDestinations = [
  {
    countryId: '0',
    country: 'France',
    countryFlagId: 'FR',
    countryLink: '/france',
    regions: [
      {
        regionId: '0',
        name: 'Rhône-Alpes',
        regionLink: '/france/ra',
        isOpened: false,
        destinations: [
          {
            destinationId: '0',
            name: 'Chartreuse',
            location: ['Isère'],
            zipCode: '38',
            link: '/',
            country: 'France',
          },
          {
            destinationId: '1',
            name: 'Gresse en Vercors',
            location: ['Isère'],
            zipCode: '38',
            link: '/',
            country: 'France',
          },
          {
            destinationId: '2',
            name: 'Oisans',
            location: ['Hautes-Alpes'],
            zipCode: '05',
            link: '/',
            country: 'France',
          },
          {
            destinationId: '3',
            name: 'Lac de Paladru',
            location: ['Isère'],
            zipCode: '38',
            link: '/',
            country: 'France',
          },
          {
            destinationId: '4',
            name: 'Matheysine - Vallée du Valbonnais',
            location: ['Isère'],
            zipCode: '38',
            link: '/',
            country: 'France',
          },
          {
            destinationId: '5',
            name: 'Le Pays des Ecrins',
            location: ['Isère'],
            zipCode: '38',
            link: '/',
            country: 'France',
          },
          {
            destinationId: '6',
            name: 'Ambert - Crêtes du Forez',
            location: ['Puy-de-Dôme'],
            zipCode: '63',
            link: '/',
            country: 'France',
          },
          {
            destinationId: '7',
            name: 'Arèches Beaufort',
            location: ['Savoie'],
            zipCode: '73',
            link: '/',
            country: 'France',
          },
          {
            destinationId: '8',
            name: 'Beaujolais Vert',
            location: ['Rhône'],
            zipCode: '73',
            link: '/',
            country: 'France',
          },
          {
            destinationId: '9',
            name: 'Brioude - Sud Auvergne',
            location: ['Haute-Loire'],
            zipCode: '43',
            link: '/',
            country: 'France',
          },
          {
            destinationId: '10',
            name: 'Bugey',
            location: ['Savoie'],
            zipCode: '73',
            link: '/',
            country: 'France',
          },
          {
            destinationId: '11',
            name: 'Champsaur et Valgaudemar',
            location: ['Hautes-Alpes'],
            zipCode: '05',
            link: '/',
            country: 'France',
          },
          {
            destinationId: '12',
            name: 'Vercors',
            location: ['Isère'],
            zipCode: '38',
            link: '/',
            country: 'France',
          },
        ],
      },
      {
        regionId: '1',
        name: 'Bourgogne-Franche-Comté',
        regionLink: '/france/bg',
        isOpened: false,
        destinations: [
          {
            destinationId: '0',
            name: 'Brionnais Découvertes',
            location: ['Saône-et-Loire'],
            zipCode: '71',
            link: '/',
            country: 'France',
          },
          {
            destinationId: '1',
            name: 'Altitude 800',
            location: ['Saône-et-Loire'],
            zipCode: '71',
            link: '/',
            country: 'France',
          },
          {
            destinationId: '2',
            name: 'Parc du Morvan',
            location: ['Nièvre', 'Saône-et-Loire', 'Yonne'],
            zipCode: '05',
            link: '/',
            country: 'France',
          },
          {
            destinationId: '3',
            name: 'Grand Pontarlier',
            location: ['Doubs'],
            zipCode: '25',
            link: '/',
            country: 'France',
          },
          {
            destinationId: '4',
            name: 'Pays Horloger',
            location: ['Doubs'],
            zipCode: '25',
            link: '/',
            country: 'France',
          },
        ],
      },
      {
        regionId: '2',
        name: 'Corse',
        regionLink: '/france/corse',
        isOpened: false,
        destinations: [
          {
            destinationId: '0',
            name: 'Balagne',
            location: ['Haute-Corse'],
            zipCode: '2B',
            link: '/',
            country: 'France',
          },
          {
            destinationId: '1',
            name: 'Bonifacio',
            location: ['Corse-du-Sud'],
            zipCode: '2A',
            link: '/',
            country: 'France',
          },
          {
            destinationId: '2',
            name: 'Ajaccio',
            location: ['Corse-du-Sud'],
            zipCode: '2A',
            link: '/',
            country: 'France',
          },
        ],
      },
    ],
  },
  {
    countryId: '1',
    country: 'Belgique',
    countryFlagId: 'BE',
    countryLink: '/belgium',
    destinations: [
      {
        destinationId: '0',
        name: 'Ardennes',
        location: [],
        zipCode: '',
        link: '/',
        country: 'Belgique',
      },
      {
        destinationId: '1',
        name: 'Liège',
        location: [],
        zipCode: '',
        link: '/',
        country: 'Belgique',
      },
    ],
  },
  {
    countryId: '2',
    country: 'Maroc',
    countryFlagId: 'MA',
    countryLink: '/maroc',
    destinations: [
      {
        destinationId: '0',
        name: 'Berguedà',
        location: [],
        zipCode: '',
        link: '/',
        country: 'Maroc',
      },
    ],
  },
];

export const staticLocalisations = [
  { id: 'pays-ecrins', name: 'Pays des Écrins, Hautes-Alpes, France', link: '/' },
  { id: 'pays-loire', name: 'Pays de la Loire', link: '/' },
  { id: 'pays-bas', name: 'Pays-Bas', link: '/' },
];

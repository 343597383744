export default function ({ $cookies, store }) {
  if (process.client) {
    const mouseTarget = document.documentElement;

    mouseTarget.addEventListener('mouseleave', (e) => {
      const newsletterAlreadyTrigger = !$cookies.get("newsletterHasBeenSeen");
      /* subtract the page scrolling to obtain the true cursor position */
      if (e.pageY - window.scrollY <= 0 && newsletterAlreadyTrigger) {
        store.commit('SET_NEWSLETTER_SHOW', true);
      }
    });

    setTimeout(() => {
      const newsletterAlreadyTrigger = !$cookies.get("newsletterHasBeenSeen");
      if (newsletterAlreadyTrigger) {
        store.commit('SET_NEWSLETTER_SHOW', true);
      }
    }, 10000);
  }
}

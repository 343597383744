





















































import { Vue, Component, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';

import { snowSports, footSports, bikeSports } from '~/utils/runConstants.js';
import { apiQueryBuilder } from '~/utils/common.js';

import TopMenu from '~/components/menus/TopMenu.vue';
import GlobalSearch from '~/components/modals/GlobalSearch.vue';
import ChevronRightSolid from '~/components/icons/ChevronRightSolid.vue';
import OptionsOutline from '~/components/icons/OptionsOutline.vue';
import ProfileRunSportTag from '~/components/partials/ProfileRunSportTag.vue';
import EventCategoryTag from '~/components/partials/EventCategoryTag.vue';
import MenuGlobalSearchInput from '~/components/partials/MenuGlobalSearchInput.vue';

type LocalisationItem = { id: string, name: string, length: number };

@Component({
  components: {
    TopMenu,
    GlobalSearch,
    ChevronRightSolid,
    OptionsOutline,
    ProfileRunSportTag,
    EventCategoryTag,
    MenuGlobalSearchInput,
  },
  computed: {
    ...mapState([
      'globalSearchModalIsDisplayed',
    ]),
    ...mapState('explorer', [
      'sports',
      'destinations',
      'routes',
      'events',
      'userCoords',
      'destinationsList',
      'routesList',
      'eventsList',
    ]),
    ...mapState('lang', [
      'locale',
    ]),
  },
})
export default class LocalisationMapLayout extends Vue {
  sports: Types.SportItem[];
  locale: string;
  destinations: Types.DestinationItem[];
  routes: Types.Route[];
  destinationsList: Types.DestinationList;
  routesList: Types.RouteList;
  eventsList: Types.EventList;
  events: Types.Event[];
  userCoords: Types.userCoords;

  tabToDisplay: {id: string, name: string} = this.localisationItems[0];
  allSubmittedSports: Array<Types.SportItem> = [];
  routeContainsNoQueries: boolean = false;
  hasSelectedArea: boolean = false;
  selectedAreaBounds: Types.LeafletBounds = null;
  isAroundMe: boolean = false;
  locName: string = '';

  /*
    Returns a reactive list of tabs (has to be a computed so the lengths are
    reactive when updating associated pages)
  */
  get localisationItems () {
    return [
      {
        id: 'destinations',
        name: this.$t('localisationItems.destinations'),
        length: this.destinationsList?.total,
      },
      {
        id: 'routes',
        name: this.$t('localisationItems.routes'),
        length: this.routesList?.total,
      },
      {
        id: 'events',
        name: this.$t('localisationItems.events'),
        length: this.eventsList?.total,
      },
    ];
  }

  /*
    This computed will return a value to put in the placeholder of the fake
    search input. The data returned is either based on datas that are changing
    on emits from pages, or from route URL values (when mounting page)
  */
  get locQuery () {
    if (this.locName) return this.locName;
    if (this.isAroundMe) return this.$t('globalSearch.aroundMeButton');
    if (this.hasSelectedArea) return this.$t('globalSearch.selectedArea');

    if (this.$route.query) {
      if (this.$route.query.loc) return this.$route.query.loc;
      if (this.$route.query.locname) return this.$route.query.locname;
    }
    return '';
  }

  /* Returns an array combining every static sports */
  get sportsSliced () {
    const runConstants = [...snowSports, ...bikeSports, ...footSports];
    const sliced = this.sports.slice(0);
    sliced.map((s: Types.SportItem) => {
      runConstants.map((r: Types.SportConstant) => {
        if (r.iconName === s.icon_name) {
          s.imageName = r.imageName;
        }
      });
    });
    return sliced;
  }

  /*
    When user changes its language, it is possible that this.sportsSliced don't
    get translated. We will so trigger this.populateSports to update this list
  */
  @Watch('locale')
  updateSportsIfLanguageUpdates () {
    this.populateSports(true);
  }

  /*
    The same logic is applied as this.updateSportsIfLanguageUpdates, but also
    when user changes the route back to the view
    This method will populate the sports to display in the template, that
    represent the user choices from globalSearch modal
  */
  @Watch('$route.query')
  populateSports (updater: boolean) {
    if (!updater) this.updateTab();

    this.hasSelectedArea = false;
    if (!this.routeContainsNoQueries) {
      const queries: any = this.$route.query;

      if (queries?.sport) {
        const arrayToReturn: Array<Types.SportItem> = [];

        /* eslint array-callback-return: 0 */
        this.sportsSliced.map((sport: Types.SportItem) => {
          if (Array.isArray(queries.sport)) {
            queries.sport.map((s: string) => {
              if (sport.id === Number(s)) {
                sport.checked = true;
                arrayToReturn.push(sport);
              }
            });
          } else if (sport.id === Number(queries.sport)) {
            sport.checked = true;
            arrayToReturn.push(sport);
          }
        });

        this.allSubmittedSports = arrayToReturn;
      } else this.allSubmittedSports = [];
    }
  }

  /*
    Mounted will listen to every event emit from children to update
    this.locQuery values.
    It will also trigger this.populateSports and this.updateTab to prevent any
    reactive issue and provide a default behavior to the template
  */
  async mounted () {
    document.body.classList.add('overflow-y-hidden');
    if (!this.sports.length) await this.$store.dispatch('explorer/getSports', { lang: this.locale });

    this.populateSports(false);
    this.updateTab();

    this.$root.$on('has-locname', (val: string) => {
      this.isAroundMe = false;
      this.hasSelectedArea = false;
      this.locName = val;
      this.$store.commit('explorer/SET_EXPLORER_FORCE_CALLS', true);
    });

    this.$root.$on('selected-area', (val: Types.LeafletBounds) => {
      this.locName = '';
      this.isAroundMe = false;
      this.hasSelectedArea = true;
      this.selectedAreaBounds = val;
      this.$store.commit('explorer/SET_EXPLORER_FORCE_CALLS', true);
    });

    this.$root.$on('searches-around-me', () => {
      this.aroundMeOnMounted();
      this.$store.commit('explorer/SET_EXPLORER_FORCE_CALLS', true);
    });

    if (
      this.$route.query?.aroundMe &&
      this.$route.query.aroundMe === 'true'
    ) {
      if (this.userCoords) {
        this.aroundMeOnMounted();
      } else {
        const payload = this.$route.query;
        delete payload.aroundMe;
        if (process.client) {
          history.replaceState(
            {},
            '',
          `${this.locale !== 'fr' ? `/${this.locale}` : ''}/explorer${apiQueryBuilder(payload)}`,
          );
        }
      }
    }
  }

  beforeDestroy () {
    document.body.classList.remove('overflow-y-hidden');
    this.$store.commit('explorer/SET_HAS_CHANGED_LANGUAGE', false);
    this.$store.commit('explorer/SET_EXPLORER_BOUNDS', {});
  }

  /* This method will be usefull for this.locQuery to display "around me" values */
  aroundMeOnMounted () {
    this.locName = '';
    this.isAroundMe = true;
  }

  /*
    When state value is updated, we trigger this method to update
    template style
  */
  updateTab () {
    if (this.$route.query?.tab) {
      const value: LocalisationItem | undefined = this.localisationItems.find(
        (item: LocalisationItem) => item.id === this.$route.query?.tab,
      );
      this.$store.commit('explorer/SET_LOCALISATION_TAB', value?.id || this.localisationItems[0].id);
      this.tabToDisplay = value || this.localisationItems[0];
    }
  }
}

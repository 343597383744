import { Middleware } from '@nuxt/types';

const settingsMiddleware: Middleware = async ({ store }) => {
  console.info('[settingsMiddleware]');
  store.commit('SET_GLOBALSEARCH_DISPLAY', false);

  if (!store.state.explorer.sports.length) {
    await store.dispatch('explorer/getSports', { lang: store.state.lang.locale });
  }
};

export default settingsMiddleware;









































import { Vue, Component, Prop } from 'vue-property-decorator';

import { blogNews, destinations } from '~/utils/static-datas.js';
import { anyWordMatches } from '~/utils/word';

import ChevronRightSolid from '~/components/icons/ChevronRightSolid.vue';
import BookOpenOutline from '~/components/icons/BookOpenOutline.vue';
import GlobalSearchFilteredElement from '~/components/lists/items/GlobalSearchFilteredElement.vue';

@Component({
  components: { ChevronRightSolid, GlobalSearchFilteredElement },
})
export default class GlobalSearchBlog extends Vue {
  @Prop(String) searchText: '';

  blog: Array<Types.BlogNews> = blogNews
  BookOpenOutline: any = BookOpenOutline;
  isLoading: boolean = false;

  get filteredNews () {
    if (this.searchText) {
      this.isLoading = true;

      const filtered = this.blog.filter((news: Types.BlogNews) => {
        return anyWordMatches(this.searchText, [news.title]);
      });

      // For now we are using a timeout to simulate latency
      // With API implementation, the filter search will be
      // async, and isLoading will be false after the API call
      // return the results
      setTimeout(() => {
        this.isLoading = false;
      }, 2000);

      return filtered;
    }
    return [];
  }

  findDestination (event: Types.Event) {
    /* eslint array-callback-return: 0 */
    const array: any = [];
    destinations.map((d: any) => {
      d.events.map((e: Types.Event) => {
        if (e.id === event.id) array.push(d.title);
      });
    });
    return array;
  }
}

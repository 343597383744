























import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ProfileRunSportTag extends Vue {
  @Prop(String) image: '';
  @Prop(String) sport: '';
  @Prop(Boolean) displayAsSelectedStyle: false;
  @Prop(Boolean) loading: false;
  @Prop(String) customPadding: '';
}

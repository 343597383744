



































import { Vue, Component, Prop } from 'vue-property-decorator';

import { getRunTag } from '~/utils/runConstants.js';

import ProfileRunSportTag from '~/components/partials/ProfileRunSportTag.vue';
import SpotOutline from '~/components/icons/SpotOutline.vue';

@Component({
  components: { ProfileRunSportTag, SpotOutline },
})
export default class GlobalSearchFilteredRoute extends Vue {
  @Prop(Object) route: Types.Route;
  @Prop(Map) suggested: Types.mappingOfSuggested[];

  /* Get translated sport of this.route.sport if it exists in utils datas */
  get sport () {
    return getRunTag(this.route.sport);
  }

  /* Classic switch to define template style of this.route.difficulty */
  get difficulty () {
    switch (this.route.difficulty) {
      case 0: return 'bg-gray-default';
      case 1: return 'bg-green-default';
      case 2: return 'bg-info-default';
      case 3: return 'bg-red-default';
      case 4: return 'bg-black';
      default: return 'bg-info-default';
    }
  }

  /*
    The computed will display the destination name associated with this.suggested
    filtered element
  */
  get getDestinationTitle () {
    let foundDestination: string = "";
    this.suggested.forEach((s: any) => {
      if (s.id === this.route.destination_id) foundDestination = s.title;
    });
    return foundDestination;
  }
}

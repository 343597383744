











































import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';

import XSolid from '~/components/icons/XSolid.vue';

@Component({
  components: { XSolid },
  computed: {
    ...mapState('lang', [
      'locale',
    ]),
  },
})
export default class EditableText extends Vue {
  @Prop(String) value: '';
  @Prop(String) placeholder: '';
  @Prop(Function) update: Function;
  @Prop(Boolean) disabled: false;
  @Prop(Boolean) isFocusable: false;

  locale: string;

  $refs: {
    editableTitle: HTMLInputElement,
  }

  currentlyEditing: boolean = false;

  /* Destructure the prop as a getter to avoid vue warning */
  get inputValue () {
    return this.value;
  }

  /*
    But WE DON'T EMIT THE VALUE ON SET as we have this.update prop to update
    value on parent template
  */
  set inputValue (value) {
    // this.$emit('input', value);
  }

  /*
    Adding some logic if active page is '/explorer' to focus directly on text
    (This component is only used in globalSearch modal, so the logic is included
    inside here)
  */
  mounted () {
    if (this.$route.name && this.$route.name.includes(`explorer___${this.locale}`)) {
      if (
        Object.keys(this.$route.query).length === 0 ||
        this.$route.query?.aroundMe === '' || this.$route.query?.locname === ''
      ) {
        this.$refs.editableTitle.focus();
      }
    } else {
      this.$refs.editableTitle.focus();
    }
  }

  /*
    When user clicks or focus inside the input that ALREADY CONTAINS A VALUE,
    this method allows component to be ready for following changes
  */
  startEditing () {
    if (this.disabled) return;
    this.currentlyEditing = true;
    // wait for element to be rendered in the DOM and focus it
    this.$nextTick(() => {
      this.$refs.editableTitle.focus();
    });
  }

  /*
    This method is launched when user will start typing
    in the input (blank value)
  */
  onFocus () {
    const el = this.$refs.editableTitle;
    const updateHeight = () => {
      // set the height to auto to force reevaluating element's layout
      // so that the scroll height is correct
      el.style.height = 'auto';
      el.style.height = `${el.scrollHeight}px`;
    };
    updateHeight();
    el.addEventListener('input', updateHeight, false);
  }

  /*
    This method is triggered after a debounce to emit value submitted by user
    and read in parent
  */
  async updateDebounceModel (val: string) {
    const newValue = val.trim();
    await this.update(newValue);
    this.$emit('input', newValue);
  }
};

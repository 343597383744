import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { apiQueryBuilder } from "~/utils/common";

// =================================== STATE ===================================

export const state: Types.ActivitiesState = () => ({
  activities: [],
  canLoadMoreActivities: false,
  activitiesStats: null,
  cardsDisplay: 'CARD',
  focusedActivity: null,
  focusedTrack: null,
  filteredSports: [],
  filteredDate: null,
  activitiesListOffset: 0,
  loadingActivities: false,
  loadingStats: false,
});

export type RootState = ReturnType<typeof state>

// ================================== GETTERS ==================================

export const getters: GetterTree<RootState, RootState> = {
};

// ================================= MUTATIONS =================================

export const mutations: MutationTree<RootState> = {
  RESET_ACTIVITIES (state) {
    state.activities = [];
  },
  RESET_ALL (state) {
    state.activities = [];
    state.activitiesStats = null;
    state.focusedActivity = null;
    state.focusedTrack = null;
    state.cardsDisplay = 'CARD';
    state.filteredSports = [];
    state.filteredDate = null;
    state.activitiesListOffset = 0;
  },
  SET_LOADING_ACTIVITIES (state, payload: boolean) {
    state.loadingActivities = payload;
  },
  SET_LOADING_STATS (state, payload: boolean) {
    state.loadingStats = payload;
  },
  SET_ACTIVITIES (state, payload: Types.Pagination<Types.Activity>) {
    state.activities = payload.items;
    state.canLoadMoreActivities = payload.has_more;
  },
  SET_APPEND_ACTIVITIES (state, payload: Types.Pagination<Types.Activity>) {
    /* eslint array-callback-return: 0 */
    payload.items.map((item: Types.Activity) => {
      state.activities.push(item);
    });
    state.canLoadMoreActivities = payload.has_more;
  },
  SET_ACTIVITIES_STATS (state, payload: Types.ActivitiesStats) {
    state.activitiesStats = payload;
  },
  SET_FOCUSED_ACTIVITY (state, payload: Types.Activity) {
    state.focusedActivity = payload;
  },
  SET_FOCUSED_TRACK (state, payload: Types.Track) {
    state.focusedTrack = payload;
  },
  SET_CARDS_DISPLAY (state, type: Types.CardDisplay) {
    state.cardsDisplay = type;
  },
  SET_FILTERED_SPORTS (state, payload: Array<Types.Sport>) {
    state.filteredSports = payload;
  },
  SET_FILTERED_DATE (state, payload: Types.DateRange) {
    state.filteredDate = payload;
  },
  SET_ACTIVITIES_OFFSET (state, number: number) {
    state.activitiesListOffset = number;
  },
};

// ================================== ACTIONS ==================================

export const actions: ActionTree<RootState, RootState> = {
  async getActivities (ctx, payload: any) {
    const params = {
      offset: payload.offset,
      limit: payload.limit,
      start_after: payload.start_after,
      start_before: payload.start_before,
      sport_ids: (payload.sports && payload.sports.length) ? payload.sports : undefined,
    };

    ctx.commit('SET_LOADING_ACTIVITIES', true);

    const { data: userData } = await this.$axios.get(`/activities${apiQueryBuilder(params)}`);

    if (payload.loadMoreActivities) {
      ctx.commit('SET_APPEND_ACTIVITIES', userData);
    } else {
      ctx.commit('SET_ACTIVITIES', userData);
    }

    await ctx.dispatch('getActivitiesStats', params);

    ctx.commit('SET_LOADING_ACTIVITIES', false);

    return userData;
  },

  async getActivitiesStats (ctx, payload) {
    const params = payload;

    ctx.commit('SET_LOADING_STATS', true);

    const { data: stats } = await this.$axios.get(`/activities/stats${apiQueryBuilder(params)}`);

    ctx.commit('SET_ACTIVITIES_STATS', stats);
    ctx.commit('SET_LOADING_STATS', false);
  },

  async getActivity (ctx, payload: any) {
    ctx.commit('SET_LOADING_ACTIVITIES', true);
    ctx.commit('SET_LOADING_STATS', true);

    const { data } = await this.$axios.get(`${payload.previewToken === undefined ? '/activities/' + payload.activityId : '/public/activities/' + payload.activityId + '/preview/' + payload.previewToken}`);

    await ctx.dispatch('getActivityTrack', payload);

    ctx.commit('SET_FOCUSED_ACTIVITY', data);

    ctx.commit('SET_LOADING_ACTIVITIES', false);
    ctx.commit('SET_LOADING_STATS', false);

    return data;
  },

  async getActivityTrack (ctx, payload: any) {
    const { data } = await this.$axios.get(`${payload.previewToken === undefined ? '/activities/' + payload.activityId + '/track' : '/public/activities/' + payload.activityId + '/track/preview/' + payload.previewToken}`);

    ctx.commit('SET_FOCUSED_TRACK', data);

    return data;
  },

  async deleteActivity (ctx, activityId) {
    await this.$axios.delete(`/activities/${activityId}`);
    ctx.commit('SET_FOCUSED_ACTIVITY', null);
    ctx.commit('SET_FOCUSED_TRACK', null);

    const payload = {
      offset: 0,
      limit: 12,
      start_after: ctx.state.filteredDate?.startDate,
      start_before: ctx.state.filteredDate?.endDate,
      sports: (ctx.state.filteredSports && ctx.state.filteredSports.length) ? ctx.state.filteredSports.map((filter: any) => filter.id) : undefined,
    };

    await ctx.dispatch('getActivities', payload);
  },

  async updateActivity (ctx, { activityId, ...body }) {
    await this.$axios.put(`/activities/${activityId}`, body);
    await ctx.dispatch('getActivity', activityId);
    await ctx.dispatch('getActivities', {
      offset: ctx.state.activitiesListOffset,
      limit: 12,
      start_after: ctx.state.filteredDate?.startDate,
      start_before: ctx.state.filteredDate?.endDate,
      sports: (ctx.state.filteredSports && ctx.state.filteredSports.length) ? ctx.state.filteredSports.map((filter: any) => filter.id) : undefined,
    });
  },

  async reportRoute (ctx, payload) {
    await this.$axios.post(`/activities/report-problem?lang=${payload.lang}`, payload.content);
  },
};








































import { Vue, Component } from 'vue-property-decorator';
import { mapState } from 'vuex';

import TopMenu from '~/components/menus/TopMenu.vue';
import SettingsMenu from '~/components/menus/SettingsMenu.vue';
import ChevronRightSolid from '~/components/icons/ChevronRightSolid.vue';
import GlobalSearch from '~/components/modals/GlobalSearch.vue';

@Component({
  components: { TopMenu, SettingsMenu, ChevronRightSolid, GlobalSearch },
  computed: {
    ...mapState([
      'globalSearchModalIsDisplayed',
    ]),
  },
})
export default class SignInLayout extends Vue {
  /* Returns translated page name from route url value */
  get pageName () {
    return this.$t(`settingsMenu.menuItems.${this.$route.path.split('/')[2]}`);
  }
}


















































import { Vue, Component } from 'vue-property-decorator';
import { mapState } from "vuex";

@Component({

  computed: {
    ...mapState('user', ['newsletterHasBeenSeen']),
  },
})
export default class NewsletterModal extends Vue {
  email: string = "";
  validationMessage = "";
  newsletterHasBeenSeen: boolean;

  mounted () {
    this.$store.commit('user/STORE_NEWSLETTER_HAS_BEEN_SEEN', !!this.$cookies.get('newsletterHasBeenSeen'));
  }

  hideNewsletterModal () {
    this.$store.commit('SET_NEWSLETTER_SHOW', false);
    this.$cookies.set("newsletterHasBeenSeen", true);
  }

  async submit () {
    const email = this.email;
    const response = await this.$store.dispatch('user/newsletterHasBeenSeen', { userEmail: email });
    if (response === 200) {
      this.validationMessage = this.$t('newsletter.validation');
    } else {
      this.validationMessage = this.$t('newsletter.error');
    }
  }
}

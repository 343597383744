


























import { Vue, Component, Prop } from 'vue-property-decorator';

import Sparkles from '~/components/icons/Sparkles.vue';

@Component({
  components: { Sparkles },
})
export default class GlobalSearchFilteredDestination extends Vue {
  @Prop(Object) destination: Types.DestinationItem;
}


export default ({ app }, inject) => {
  inject('thumbnailUrl', (url, size) => {
    let final = '';
    if (url) {
      final = url.replace('/media', `/media/${size}`);
    }
    return final;
  });
};

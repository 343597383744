









































import { Vue, Component, Prop } from 'vue-property-decorator';

import MapOutline from '~/components/icons/MapOutline.vue';
import GlobalSearchFilteredElement from '~/components/lists/items/GlobalSearchFilteredElement.vue';
import CalendarOutline from '~/components/icons/CalendarOutline.vue';

@Component({
  components: { MapOutline, GlobalSearchFilteredElement },
})
export default class GlobalSearchRoutes extends Vue {
  @Prop(String) searchText: '';
  @Prop(Array) eventsResults: Types.Event[];
  @Prop() suggested: Types.mappingOfSuggested[];

  CalendarOutline: Function = CalendarOutline;
  isLoading: boolean = false;

  /*
    This method will display in template the first destination title of
    each event result
  */
  findDestination (event: Types.Event) {
    /* eslint array-callback-return: 0 */
    const array: Array<string> = [];
    this.eventsResults.map((d: Types.Event) => {
      d.destinations?.map((e: Types.DestinationItem) => {
        if (d.id === event.id) array.push(e.title);
      });
    });
    return array;
  }
}

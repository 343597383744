













































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';

import { countries } from '~/utils/countries.js';
import { countriesCoordinates } from '~/utils/countriesCoordinates.js';

import MapOutline from '~/components/icons/MapOutline.vue';
import GlobalSearchDestinationsList from '~/components/lists/GlobalSearchDestinationsList.vue';
import GlobalSearchFilteredDestination from '~/components/lists/items/GlobalSearchFilteredDestination.vue';
import LoaderPlaceholder from '~/components/icons/LoaderPlaceholder.vue';

@Component({
  components: { MapOutline, GlobalSearchDestinationsList, GlobalSearchFilteredDestination, LoaderPlaceholder },
  computed: {
    ...mapState('lang', [
      'locale',
    ]),
  },
})
export default class GlobalSearchDestinations extends Vue {
  @Prop(String) searchText: '';
  @Prop(Array) destinationsResults: [];
  @Prop(Object) defaultDestinations: Types.SearchSuggestions;

  isLoading: boolean = false;
  locale: string;
  destinationsSortedByCountries: Types.DestinationCountry[] = [];

  /*
    This method will map inside this.defaultDestinations to display elements :
    the country, with its flag, then regions with name, and finally destinations
    with name.

    Each element is clickable in template : country and region will return
    coordinates to be used in explorer view, and destination will navigate to
    explorer/destinations/destination_id view
  */
  @Watch('defaultDestinations')
  populateDestinations (val: Types.SearchSuggestions) {
    const arrayOfCountries: Types.DestinationCountry[] = [];

    for (const country in val) {
      const matchedCountry: Types.Country | undefined = countries.find((c: Types.Country) => c.id === country.toUpperCase());
      const object: any = {
        id: country,
        name: matchedCountry?.name,
        flag: matchedCountry?.flag,
        regions: [],
        destinations: [],
      };

      for (const region in val[country]) {
        if (country === 'FR') {
          object.regions.push({
            name: region,
            isOpened: false,
            destinations: val[country][region],
          });
        } else {
          for (const countryRegion in val[country]) {
            for (let i = 0; i < val[country][countryRegion].length; i++) {
              if (!object.destinations.includes((d: any) => d.destination.id === val[country][countryRegion][i].destination.id)) {
                object.destinations.push(val[country][countryRegion][i]);
              }
            }
          }
        }
      }
      object.regions.sort(
        (a: Types.DestinationCountry, b: Types.DestinationCountry) =>
          a.name.localeCompare(b.name),
      );

      object.destinations = [...new Set(object.destinations)];

      arrayOfCountries.push(object);
    }

    /* eslint array-callback-return: 0 */
    countriesCoordinates.map((
      cCord: {
        country: string,
        countryCode: string,
        long: number,
        lat: number,
        bounds: number[]
      },
    ) => {
      arrayOfCountries.map((c: Types.DestinationCountry) => {
        if (cCord.countryCode === c.id) {
          c.bounds = cCord.bounds;
        }
      });
    });

    this.destinationsSortedByCountries = arrayOfCountries;
  }

  /* Creating the array at mounted */
  mounted () {
    this.populateDestinations(this.defaultDestinations);
  }
}

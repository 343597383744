
















































import { Vue, Component, Prop } from 'vue-property-decorator';

import ExperiencesPickerCard from '~/components/lists/items/ExperiencesPickerCard.vue';
import GlobalSwiper from '~/components/partials/GlobalSwiper.vue';

@Component({
  components: { ExperiencesPickerCard, GlobalSwiper },
})
export default class ExperiencesPicker extends Vue {
  @Prop(String) label: '';
  @Prop(Array) sportsWithoutChildren: [];
  @Prop(Boolean) singleSelect: false;
  @Prop([String, Object, Array, Number]) selected: string | {} | [] | number;
  @Prop(Boolean) displayAsCarousel: false;
  @Prop(Boolean) displayAsChips: false;
  @Prop(Boolean) isXs: false;
  @Prop(Boolean) customUnselectedStyle: false;
  @Prop(Boolean) singleCardReset: false;
  @Prop(Boolean) centerOnMobile: false;

  @Prop(Boolean) hideLeftArrow: false;
  @Prop(Boolean) hideDots: false;

  sportsArray: Array<any> = [];
  /*
    This method will emit an array of selected cards in the picker.
    Based on children's properties, we adapt the array to emit
  */
  constructArray (item: Types.SportPicker) {
    if (this.singleSelect) {
      if (item.sport) {
        return this.$emit('sport-selected', item.sport.id);
      }
      this.$emit('sport-selected', item.id);
      return item.id;
    }

    if (item.action === 'ADD') {
      this.sportsArray.push(item.sport);
    }

    if (item.action === 'REMOVE') {
      const arrayIndex = this.sportsArray.indexOf((i: any) => {
        return i.id === item.sport.id;
      });
      this.sportsArray.splice(arrayIndex, 1);
    };
    this.$emit('sport-selected', this.sportsArray);
  }
};

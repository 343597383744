export const countries =
[
  { "name": "Afghanistan", "id": "AF", "flag": "/icons/flags/AF.svg" },
  { "name": "land Islands", "id": "AX", "flag": "/icons/flags/AX.svg" },
  { "name": "Albania", "id": "AL", "flag": "/icons/flags/AL.svg" },
  { "name": "Algeria", "id": "DZ", "flag": "/icons/flags/DZ.svg" },
  { "name": "American Samoa", "id": "AS", "flag": "/icons/flags/AS.svg" },
  { "name": "Andorra", "id": "AD", "flag": "/icons/flags/AD.svg" },
  { "name": "Angola", "id": "AO", "flag": "/icons/flags/AO.svg" },
  { "name": "Anguilla", "id": "AI", "flag": "/icons/flags/AI.svg" },
  { "name": "Antarctica", "id": "AQ" },
  { "name": "Antigua and Barbuda", "id": "AG", "flag": "/icons/flags/AG.svg" },
  { "name": "Argentina", "id": "AR", "flag": "/icons/flags/AR.svg" },
  { "name": "Armenia", "id": "AM", "flag": "/icons/flags/AM.svg" },
  { "name": "Aruba", "id": "AW", "flag": "/icons/flags/AW.svg" },
  { "name": "Australia", "id": "AU", "flag": "/icons/flags/AU.svg" },
  { "name": "Austria", "id": "AT", "flag": "/icons/flags/AT.svg" },
  { "name": "Azerbaijan", "id": "AZ", "flag": "/icons/flags/AZ.svg" },
  { "name": "Bahamas", "id": "BS", "flag": "/icons/flags/BS.svg" },
  { "name": "Bahrain", "id": "BH", "flag": "/icons/flags/BH.svg" },
  { "name": "Bangladesh", "id": "BD", "flag": "/icons/flags/BD.svg" },
  { "name": "Barbados", "id": "BB", "flag": "/icons/flags/BB.svg" },
  { "name": "Belarus", "id": "BY", "flag": "/icons/flags/BY.svg" },
  { "name": "Belgium", "id": "BE", "flag": "/icons/flags/BE.svg" },
  { "name": "Belize", "id": "BZ", "flag": "/icons/flags/BZ.svg" },
  { "name": "Benin", "id": "BJ", "flag": "/icons/flags/BJ.svg" },
  { "name": "Bermuda", "id": "BM", "flag": "/icons/flags/BM.svg" },
  { "name": "Bhutan", "id": "BT", "flag": "/icons/flags/BT.svg" },
  { "name": "Bolivia", "id": "BO", "flag": "/icons/flags/BO.svg" },
  { "name": "Bosnia and Herzegovina", "id": "BA", "flag": "/icons/flags/BA.svg" },
  { "name": "Botswana", "id": "BW", "flag": "/icons/flags/BW.svg" },
  { "name": "Bouvet Island", "id": "BV", "flag": "/icons/flags/BV.svg" },
  { "name": "Brazil", "id": "BR", "flag": "/icons/flags/BR.svg" },
  { "name": "British Indian Ocean Territory", "id": "IO", "flag": "/icons/flags/IO.svg" },
  { "name": "Brunei Darussalam", "id": "BN", "flag": "/icons/flags/BN.svg" },
  { "name": "Bulgaria", "id": "BG", "flag": "/icons/flags/BG.svg" },
  { "name": "Burkina Faso", "id": "BF", "flag": "/icons/flags/BF.svg" },
  { "name": "Burundi", "id": "BI", "flag": "/icons/flags/BI.svg" },
  { "name": "Cambodia", "id": "KH", "flag": "/icons/flags/KH.svg" },
  { "name": "Cameroon", "id": "CM", "flag": "/icons/flags/CM.svg" },
  { "name": "Canada", "id": "CA", "flag": "/icons/flags/CA.svg" },
  { "name": "Cape Verde", "id": "CV", "flag": "/icons/flags/CV.svg" },
  { "name": "Cayman Islands", "id": "KY", "flag": "/icons/flags/KY.svg" },
  { "name": "Central African Republic", "id": "CF", "flag": "/icons/flags/CF.svg" },
  { "name": "Chad", "id": "TD", "flag": "/icons/flags/TD.svg" },
  { "name": "Chile", "id": "CL", "flag": "/icons/flags/CL.svg" },
  { "name": "China", "id": "CN", "flag": "/icons/flags/CN.svg" },
  { "name": "Christmas Island", "id": "CX", "flag": "/icons/flags/CX.svg" },
  { "name": "Cocos (Keeling) Islands", "id": "CC", "flag": "/icons/flags/CC.svg" },
  { "name": "Colombia", "id": "CO", "flag": "/icons/flags/CO.svg" },
  { "name": "Comoros", "id": "KM", "flag": "/icons/flags/KM.svg" },
  { "name": "Congo", "id": "CG", "flag": "/icons/flags/CG.svg" },
  { "name": "Congo, The Democratic Republic of the", "id": "CD", "flag": "/icons/flags/CD.svg" },
  { "name": "Cook Islands", "id": "CK", "flag": "/icons/flags/CK.svg" },
  { "name": "Costa Rica", "id": "CR", "flag": "/icons/flags/CR.svg" },
  { "name": "Cote D\"Ivoire", "id": "CI", "flag": "/icons/flags/CI.svg" },
  { "name": "Croatia", "id": "HR", "flag": "/icons/flags/HR.svg" },
  { "name": "Cuba", "id": "CU", "flag": "/icons/flags/CU.svg" },
  { "name": "Cyprus", "id": "CY", "flag": "/icons/flags/CY.svg" },
  { "name": "Czech Republic", "id": "CZ", "flag": "/icons/flags/CZ.svg" },
  { "name": "Denmark", "id": "DK", "flag": "/icons/flags/DK.svg" },
  { "name": "Djibouti", "id": "DJ", "flag": "/icons/flags/DJ.svg" },
  { "name": "Dominica", "id": "DM", "flag": "/icons/flags/DM.svg" },
  { "name": "Dominican Republic", "id": "DO", "flag": "/icons/flags/DO.svg" },
  { "name": "Ecuador", "id": "EC", "flag": "/icons/flags/EC.svg" },
  { "name": "Egypt", "id": "EG", "flag": "/icons/flags/EG.svg" },
  { "name": "El Salvador", "id": "SV", "flag": "/icons/flags/SV.svg" },
  { "name": "Equatorial Guinea", "id": "GQ", "flag": "/icons/flags/GQ.svg" },
  { "name": "Eritrea", "id": "ER", "flag": "/icons/flags/ER.svg" },
  { "name": "Estonia", "id": "EE", "flag": "/icons/flags/EE.svg" },
  { "name": "Ethiopia", "id": "ET", "flag": "/icons/flags/ET.svg" },
  { "name": "Falkland Islands (Malvinas)", "id": "FK", "flag": "/icons/flags/FK.svg" },
  { "name": "Faroe Islands", "id": "FO", "flag": "/icons/flags/FO.svg" },
  { "name": "Fiji", "id": "FJ", "flag": "/icons/flags/FJ.svg" },
  { "name": "Finland", "id": "FI", "flag": "/icons/flags/FI.svg" },
  { "name": "France", "id": "FR", "flag": "/icons/flags/FR.svg" },
  { "name": "French Guiana", "id": "GF", "flag": "/icons/flags/GF.svg" },
  { "name": "French Polynesia", "id": "PF", "flag": "/icons/flags/PF.svg" },
  { "name": "French Southern Territories", "id": "TF", "flag": "/icons/flags/TF.svg" },
  { "name": "Gabon", "id": "GA", "flag": "/icons/flags/GA.svg" },
  { "name": "Gambia", "id": "GM", "flag": "/icons/flags/GM.svg" },
  { "name": "Georgia", "id": "GE", "flag": "/icons/flags/GE.svg" },
  { "name": "Germany", "id": "DE", "flag": "/icons/flags/DE.svg" },
  { "name": "Ghana", "id": "GH", "flag": "/icons/flags/GH.svg" },
  { "name": "Gibraltar", "id": "GI", "flag": "/icons/flags/GI.svg" },
  { "name": "Greece", "id": "GR", "flag": "/icons/flags/GR.svg" },
  { "name": "Greenland", "id": "GL", "flag": "/icons/flags/GL.svg" },
  { "name": "Grenada", "id": "GD", "flag": "/icons/flags/GD.svg" },
  { "name": "Guadeloupe", "id": "GP", "flag": "/icons/flags/GP.svg" },
  { "name": "Guam", "id": "GU", "flag": "/icons/flags/GU.svg" },
  { "name": "Guatemala", "id": "GT", "flag": "/icons/flags/GT.svg" },
  { "name": "Guernsey", "id": "GG", "flag": "/icons/flags/GG.svg" },
  { "name": "Guinea", "id": "GN", "flag": "/icons/flags/GN.svg" },
  { "name": "Guinea-Bissau", "id": "GW", "flag": "/icons/flags/GW.svg" },
  { "name": "Guyana", "id": "GY", "flag": "/icons/flags/GY.svg" },
  { "name": "Haiti", "id": "HT", "flag": "/icons/flags/HT.svg" },
  { "name": "Heard Island and Mcdonald Islands", "id": "HM", "flag": "/icons/flags/HM.svg" },
  { "name": "Holy See (Vatican City State)", "id": "VA", "flag": "/icons/flags/VA.svg" },
  { "name": "Honduras", "id": "HN", "flag": "/icons/flags/HN.svg" },
  { "name": "Hong Kong", "id": "HK", "flag": "/icons/flags/HK.svg" },
  { "name": "Hungary", "id": "HU", "flag": "/icons/flags/HU.svg" },
  { "name": "Iceland", "id": "IS", "flag": "/icons/flags/IS.svg" },
  { "name": "India", "id": "IN", "flag": "/icons/flags/IN.svg" },
  { "name": "Indonesia", "id": "ID", "flag": "/icons/flags/ID.svg" },
  { "name": "Iran, Islamic Republic Of", "id": "IR", "flag": "/icons/flags/IER.svg" },
  { "name": "Iraq", "id": "IQ", "flag": "/icons/flags/IQ.svg" },
  { "name": "Ireland", "id": "IE", "flag": "/icons/flags/IE.svg" },
  { "name": "Isle of Man", "id": "IM", "flag": "/icons/flags/IM.svg" },
  { "name": "Israel", "id": "IL", "flag": "/icons/flags/IL.svg" },
  { "name": "Italy", "id": "IT", "flag": "/icons/flags/IT.svg" },
  { "name": "Jamaica", "id": "JM", "flag": "/icons/flags/JM.svg" },
  { "name": "Japan", "id": "JP", "flag": "/icons/flags/JP.svg" },
  { "name": "Jersey", "id": "JE", "flag": "/icons/flags/JE.svg" },
  { "name": "Jordan", "id": "JO", "flag": "/icons/flags/JO.svg" },
  { "name": "Kazakhstan", "id": "KZ", "flag": "/icons/flags/KZ.svg" },
  { "name": "Kenya", "id": "KE", "flag": "/icons/flags/KE.svg" },
  { "name": "Kiribati", "id": "KI", "flag": "/icons/flags/KI.svg" },
  { "name": "Korea, Democratic People\"S Republic of", "id": "KP", "flag": "/icons/flags/KP.svg" },
  { "name": "Korea, Republic of", "id": "KR", "flag": "/icons/flags/KR.svg" },
  { "name": "Kuwait", "id": "KW", "flag": "/icons/flags/KW.svg" },
  { "name": "Kyrgyzstan", "id": "KG", "flag": "/icons/flags/KG.svg" },
  { "name": "Lao People\"S Democratic Republic", "id": "LA", "flag": "/icons/flags/LA.svg" },
  { "name": "Latvia", "id": "LV", "flag": "/icons/flags/LV.svg" },
  { "name": "Lebanon", "id": "LB", "flag": "/icons/flags/LB.svg" },
  { "name": "Lesotho", "id": "LS", "flag": "/icons/flags/LS.svg" },
  { "name": "Liberia", "id": "LR", "flag": "/icons/flags/LR.svg" },
  { "name": "Libyan Arab Jamahiriya", "id": "LY", "flag": "/icons/flags/LY.svg" },
  { "name": "Liechtenstein", "id": "LI", "flag": "/icons/flags/LI.svg" },
  { "name": "Lithuania", "id": "LT", "flag": "/icons/flags/LT.svg" },
  { "name": "Luxembourg", "id": "LU", "flag": "/icons/flags/LU.svg" },
  { "name": "Macao", "id": "MO", "flag": "/icons/flags/MO.svg" },
  { "name": "Macedonia, The Former Yugoslav Republic of", "id": "MK", "flag": "/icons/flags/MK.svg" },
  { "name": "Madagascar", "id": "MG", "flag": "/icons/flags/MG.svg" },
  { "name": "Malawi", "id": "MW", "flag": "/icons/flags/MW.svg" },
  { "name": "Malaysia", "id": "MY", "flag": "/icons/flags/MY.svg" },
  { "name": "Maldives", "id": "MV", "flag": "/icons/flags/MV.svg" },
  { "name": "Mali", "id": "ML", "flag": "/icons/flags/ML.svg" },
  { "name": "Malta", "id": "MT", "flag": "/icons/flags/MT.svg" },
  { "name": "Marshall Islands", "id": "MH", "flag": "/icons/flags/MH.svg" },
  { "name": "Martinique", "id": "MQ", "flag": "/icons/flags/MQ.svg" },
  { "name": "Mauritania", "id": "MR", "flag": "/icons/flags/MR.svg" },
  { "name": "Mauritius", "id": "MU", "flag": "/icons/flags/MU.svg" },
  { "name": "Mayotte", "id": "YT", "flag": "/icons/flags/YT.svg" },
  { "name": "Mexico", "id": "MX", "flag": "/icons/flags/MX.svg" },
  { "name": "Micronesia, Federated States of", "id": "FM", "flag": "/icons/flags/FM.svg" },
  { "name": "Moldova, Republic of", "id": "MD", "flag": "/icons/flags/MD.svg" },
  { "name": "Monaco", "id": "MC", "flag": "/icons/flags/MC.svg" },
  { "name": "Mongolia", "id": "MN", "flag": "/icons/flags/MN.svg" },
  { "name": "Montenegro", "id": "ME", "flag": "/icons/flags/ME.svg" },
  { "name": "Montserrat", "id": "MS", "flag": "/icons/flags/MS.svg" },
  { "name": "Morocco", "id": "MA", "flag": "/icons/flags/MA.svg" },
  { "name": "Mozambique", "id": "MZ", "flag": "/icons/flags/MZ.svg" },
  { "name": "Myanmar", "id": "MM", "flag": "/icons/flags/MN.svg" },
  { "name": "Namibia", "id": "NA", "flag": "/icons/flags/NA.svg" },
  { "name": "Nauru", "id": "NR", "flag": "/icons/flags/NR.svg" },
  { "name": "Nepal", "id": "NP", "flag": "/icons/flags/NP.svg" },
  { "name": "Netherlands", "id": "NL", "flag": "/icons/flags/NL.svg" },
  { "name": "Netherlands Antilles", "id": "AN" },
  { "name": "New Caledonia", "id": "NC", "flag": "/icons/flags/NC.svg" },
  { "name": "New Zealand", "id": "NZ", "flag": "/icons/flags/NZ.svg" },
  { "name": "Nicaragua", "id": "NI", "flag": "/icons/flags/NI.svg" },
  { "name": "Niger", "id": "NE", "flag": "/icons/flags/NE.svg" },
  { "name": "Nigeria", "id": "NG", "flag": "/icons/flags/NG.svg" },
  { "name": "Niue", "id": "NU", "flag": "/icons/flags/NU.svg" },
  { "name": "Norfolk Island", "id": "NF", "flag": "/icons/flags/NF.svg" },
  { "name": "Northern Mariana Islands", "id": "MP", "flag": "/icons/flags/MP.svg" },
  { "name": "Norway", "id": "NO", "flag": "/icons/flags/NO.svg" },
  { "name": "Oman", "id": "OM", "flag": "/icons/flags/OM.svg" },
  { "name": "Pakistan", "id": "PK", "flag": "/icons/flags/PK.svg" },
  { "name": "Palau", "id": "PW", "flag": "/icons/flags/PW.svg" },
  { "name": "Palestinian Territory, Occupied", "id": "PS", "flag": "/icons/flags/PS.svg" },
  { "name": "Panama", "id": "PA", "flag": "/icons/flags/PA.svg" },
  { "name": "Papua New Guinea", "id": "PG", "flag": "/icons/flags/PG.svg" },
  { "name": "Paraguay", "id": "PY", "flag": "/icons/flags/PY.svg" },
  { "name": "Peru", "id": "PE", "flag": "/icons/flags/PE.svg" },
  { "name": "Philippines", "id": "PH", "flag": "/icons/flags/PH.svg" },
  { "name": "Pitcairn", "id": "PN", "flag": "/icons/flags/PN.svg" },
  { "name": "Poland", "id": "PL", "flag": "/icons/flags/PL.svg" },
  { "name": "Portugal", "id": "PT", "flag": "/icons/flags/PT.svg" },
  { "name": "Puerto Rico", "id": "PR", "flag": "/icons/flags/PR.svg" },
  { "name": "Qatar", "id": "QA", "flag": "/icons/flags/QA.svg" },
  { "name": "Reunion", "id": "RE", "flag": "/icons/flags/RE.svg" },
  { "name": "Romania", "id": "RO", "flag": "/icons/flags/RO.svg" },
  { "name": "Russian Federation", "id": "RU", "flag": "/icons/flags/RU.svg" },
  { "name": "RWANDA", "id": "RW", "flag": "/icons/flags/RW.svg" },
  { "name": "Saint Helena", "id": "SH", "flag": "/icons/flags/SH.svg" },
  { "name": "Saint Kitts and Nevis", "id": "KN", "flag": "/icons/flags/KN.svg" },
  { "name": "Saint Lucia", "id": "LC", "flag": "/icons/flags/LC.svg" },
  { "name": "Saint Pierre and Miquelon", "id": "PM", "flag": "/icons/flags/PM.svg" },
  { "name": "Saint Vincent and the Grenadines", "id": "VC", "flag": "/icons/flags/VC.svg" },
  { "name": "Samoa", "id": "WS", "flag": "/icons/flags/WS.svg" },
  { "name": "San Marino", "id": "SM", "flag": "/icons/flags/SM.svg" },
  { "name": "Sao Tome and Principe", "id": "ST", "flag": "/icons/flags/ST.svg" },
  { "name": "Saudi Arabia", "id": "SA", "flag": "/icons/flags/SA.svg" },
  { "name": "Senegal", "id": "SN", "flag": "/icons/flags/SN.svg" },
  { "name": "Serbia", "id": "RS", "flag": "/icons/flags/RS.svg" },
  { "name": "Seychelles", "id": "SC", "flag": "/icons/flags/SC.svg" },
  { "name": "Sierra Leone", "id": "SL", "flag": "/icons/flags/SL.svg" },
  { "name": "Singapore", "id": "SG", "flag": "/icons/flags/SG.svg" },
  { "name": "Slovakia", "id": "SK", "flag": "/icons/flags/SK.svg" },
  { "name": "Slovenia", "id": "SI", "flag": "/icons/flags/SI.svg" },
  { "name": "Solomon Islands", "id": "SB", "flag": "/icons/flags/SB.svg" },
  { "name": "Somalia", "id": "SO", "flag": "/icons/flags/SO.svg" },
  { "name": "South Africa", "id": "ZA", "flag": "/icons/flags/ZA.svg" },
  { "name": "South Georgia and the South Sandwich Islands", "id": "GS", "flag": "/icons/flags/GS.svg" },
  { "name": "Spain", "id": "ES", "flag": "/icons/flags/ES.svg" },
  { "name": "Sri Lanka", "id": "LK", "flag": "/icons/flags/LK.svg" },
  { "name": "Sudan", "id": "SD", "flag": "/icons/flags/SD.svg" },
  { "name": "Suriname", "id": "SR", "flag": "/icons/flags/SR.svg" },
  { "name": "Svalbard and Jan Mayen", "id": "SJ", "flag": "/icons/flags/SJ.svg" },
  { "name": "Swaziland", "id": "SZ", "flag": "/icons/flags/SZ.svg" },
  { "name": "Sweden", "id": "SE", "flag": "/icons/flags/SE.svg" },
  { "name": "Switzerland", "id": "CH", "flag": "/icons/flags/CH.svg" },
  { "name": "Syrian Arab Republic", "id": "SY", "flag": "/icons/flags/SY.svg" },
  { "name": "Taiwan, Province of China", "id": "TW", "flag": "/icons/flags/TW.svg" },
  { "name": "Tajikistan", "id": "TJ", "flag": "/icons/flags/TJ.svg" },
  { "name": "Tanzania, United Republic of", "id": "TZ", "flag": "/icons/flags/TZ.svg" },
  { "name": "Thailand", "id": "TH", "flag": "/icons/flags/TH.svg" },
  { "name": "Timor-Leste", "id": "TL", "flag": "/icons/flags/TL.svg" },
  { "name": "Togo", "id": "TG", "flag": "/icons/flags/TG.svg" },
  { "name": "Tokelau", "id": "TK", "flag": "/icons/flags/TK.svg" },
  { "name": "Tonga", "id": "TO", "flag": "/icons/flags/TO.svg" },
  { "name": "Trinidad and Tobago", "id": "TT", "flag": "/icons/flags/TT.svg" },
  { "name": "Tunisia", "id": "TN", "flag": "/icons/flags/TN.svg" },
  { "name": "Turkey", "id": "TR", "flag": "/icons/flags/TR.svg" },
  { "name": "Turkmenistan", "id": "TM", "flag": "/icons/flags/TM.svg" },
  { "name": "Turks and Caicos Islands", "id": "TC", "flag": "/icons/flags/TC.svg" },
  { "name": "Tuvalu", "id": "TV", "flag": "/icons/flags/TV.svg" },
  { "name": "Uganda", "id": "UG", "flag": "/icons/flags/UG.svg" },
  { "name": "Ukraine", "id": "UA", "flag": "/icons/flags/UA.svg" },
  { "name": "United Arab Emirates", "id": "AE", "flag": "/icons/flags/AE.svg" },
  { "name": "United Kingdom", "id": "GB", "flag": "/icons/flags/GB.svg" },
  { "name": "United States", "id": "US", "flag": "/icons/flags/US.svg" },
  { "name": "United States Minor Outlying Islands", "id": "UM", "flag": "/icons/flags/UM.svg" },
  { "name": "Uruguay", "id": "UY", "flag": "/icons/flags/UY.svg" },
  { "name": "Uzbekistan", "id": "UZ", "flag": "/icons/flags/UZ.svg" },
  { "name": "Vanuatu", "id": "VU", "flag": "/icons/flags/VU.svg" },
  { "name": "Venezuela", "id": "VE", "flag": "/icons/flags/VE.svg" },
  { "name": "Viet Nam", "id": "VN", "flag": "/icons/flags/VN.svg" },
  { "name": "Virgin Islands, British", "id": "VG", "flag": "/icons/flags/VG.svg" },
  { "name": "Virgin Islands, U.S.", "id": "VI", "flag": "/icons/flags/VI.svg" },
  { "name": "Wallis and Futuna", "id": "WF", "flag": "/icons/flags/WF.svg" },
  { "name": "Yemen", "id": "YE", "flag": "/icons/flags/YE.svg" },
  { "name": "Zambia", "id": "ZM", "flag": "/icons/flags/ZM.svg" },
  { "name": "Zimbabwe", "id": "ZW", "flag": "/icons/flags/ZW.svg" },
];

export function getBrowserLanguage (nav) {
  let language = 'en';

  if (nav === 'fr' || nav.includes('fr-')) language = 'fr';
  if (nav === 'en' || nav.includes('en-')) language = 'en';
  if (nav === 'es' || nav.includes('es-')) language = 'es';
  if (nav === 'it' || nav.includes('it-')) language = 'it';
  if (nav === 'ca') language = 'ca';

  return language;
}

<template>
  <div
    :id="tooltipId"
    ref="tooltip"
    class="fixed z-50 pointer-events-none transition-opacity ease-in-out"
    :class="[
      !active ? 'opacity-0' : 'opacity-100',
      forceWrap ? 'text-center' : 'whitespace-no-wrap',
    ]"
    :style="{
      'transition-duration': `${duration}ms`,
      'transition-delay': `${transitionDelay}ms`,
      'left': `${left}px`,
      'top': `${top}px`,
      'width': width ? `${width}px` : 'initial',
    }"
  >
    <div
      class="fixed w-0 h-0 text-gray-default transform -translate-x-1/2"
      :class="tooltipIsAbove
        ? 'translate-y-full rotate-180 mt-1.5'
        : '-translate-y-full rotate-0'
      "
      style="border-color:transparent; border-bottom-color:currentColor;"
      :style="{
        'border-width': `${arrowWidth}px`,
        'left': arrowLeft ? `${arrowLeft}px` : '50%',
      }"
    />
    <div
      class="bg-gray-default text-white rounded shadow-dropdown"
      style="padding: 1px 8px"
    >
      <div class="text-xs">{{ text || '{ Missing tooltip text }' }}</div>
    </div>
  </div>
</template>

<script>
const screenDist = 24;
const contentDist = 8;

export default {
  name: 'Tooltip',

  props: {
    tooltipId: { type: String, required: true, default: 'tooltip' },
    text: { type: String, required: false, default: '' },
    maxWidth: { type: Number, required: false, default: 0 },
    duration: { type: Number, required: false, default: 200 },
    delay: { type: Number, required: false, default: 100 },
    active: { type: Boolean, required: false, default: false },
  },

  data () {
    return {
      forceWrap: false,
      arrowWidth: 6,
      arrowLeft: undefined,
      tooltipIsAbove: false,
      left: 0,
      top: 0,
      width: 0,
    };
  },

  computed: {
    /* This computed will add a delay to tooltip transition if provided by prop */
    transitionDelay () {
      return this.active ? this.delay : 0;
    },
  },

  methods: {
    /*
      This method will display the tooltip, and calculate the position of where
      it has to be displayed and the direction of the animation
    */
    positionTooltip (parentEl) {
      this.width = 0;
      if (process.client) {
        setTimeout(() => {
          const tooltip = this.$el.getBoundingClientRect();

          const tooLong = this.maxWidth ? tooltip.width > this.maxWidth : false;
          this.width = tooLong ? this.maxWidth : tooltip.width;
          this.forceWrap = tooLong;

          const content = parentEl.getBoundingClientRect();

          // By default, position the tooltip under the content, aligned in the center
          // if the left or the right part overflows outside of the screen,
          // bring the tooltip within the tolerable distance from the screen border
          const contentCenter = content.left + content.width / 2;
          var left = contentCenter - this.width / 2;
          const minLeft = screenDist;
          const right = left + this.width;
          const maxRight = window.innerWidth - screenDist;

          this.left = left < minLeft
            ? minLeft
            : right > maxRight
              ? left - (right - maxRight)
              : left;

          this.arrowLeft = contentCenter;

          // If the tooltip overflows the bottom border, position it above the content instead
          var top = content.bottom + contentDist;
          const bottom = top + tooltip.height;
          const maxBottom = window.innerHeight - screenDist;

          this.tooltipIsAbove = bottom > maxBottom;
          this.top = this.tooltipIsAbove ? content.top - tooltip.height - contentDist : top;
        }, 0);
      }
    },
  },
};
</script>

























































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import ExperiencesPicker from '~/components/lists/ExperiencesPicker.vue';
import ExperiencesPickerCard from '~/components/lists/items/ExperiencesPickerCard.vue';

type AllSportItem = {
  id: string;
  name: string;
  checked: boolean;
}

type ActionButton = {
  action: string;
  sport?: {
    checked: boolean;
    id: string;
    name: string;
  }
}

@Component({
  components: { ExperiencesPicker, ExperiencesPickerCard },
})
export default class GlobalSearchSports extends Vue {
  @Prop(Array) preSelectedSports: Array<Types.SportItem>;
  @Prop(Array) sportsUniverses: Types.SportUniverseObject[];

  submittedSports: Array<Types.Sport> = [];
  universesToShow: Types.SportUniverseObject[] = [];

  /*
    Prop this.sportsUniverses isn't reactive when mounted, so we slice it into
    another data so we can modify it with user actions
  */
  @Watch('sportsUniverses', { immediate: true })
  populateDefaults (val: Types.SportUniverseObject[]) {
    if (!this.preSelectedSports.length) this.universesToShow = this.sportsUniverses.slice(0);
  }

  /*
    If this.preSelectedSports exists when mounted (same reactive issue),
    we preselect items in template and update associated datas
  */
  @Watch('preSelectedSports', { immediate: true })
  prePopulateValues (val: Array<Types.SportItem>) {
    if (val.length) {
      val.map((v: Types.SportItem) => {
        this.sportsUniverses.map((u: Types.SportUniverseObject) => {
          if (u.universe === v.universe) {
            u.sports?.map((t: Types.SportItem) => {
              if (t.id === v.id) {
                t.checked = true;
                v.checked = true;
              }
            });
          }
        });
      });

      this.sportsUniverses.map((u: Types.SportUniverseObject) => {
        if (u.sports?.every((s: Types.SportItem) => s.checked)) {
          if (u.allSports) u.allSports.checked = true;
        }
      });

      this.universesToShow = this.sportsUniverses.slice(0);
    }
  }

  mounted () {
    // This listener will reset every selected sports
    this.$root.$on('reset-sports', () => {
      this.applyAllSportsHandle({ action: 'REMOVE' }, this.universesToShow);
    });
  }

  /* Handles user's action when clicking a sport */
  applyAllSportsHandle (val: ActionButton, list: any) {
    /* eslint array-callback-return: 0 */
    list?.sports?.map((s: Types.SportItem) => {
      if (val.action === 'ADD') {
        s.checked = true;
      } else {
        s.checked = false;
      }
    });
  }

  /*
    This method updates the style of the sport's universe based on its children
    values
  */
  allSportStyleChecker (allSportItem: AllSportItem, list: Array<Types.SportItem>) {
    const testIfAllSportsHasAFalseValue: boolean = list.some(s => s.checked === false);

    if (testIfAllSportsHasAFalseValue) allSportItem.checked = false;
    else allSportItem.checked = true;
  }
}

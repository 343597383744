



















import { Vue, Component, Prop } from 'vue-property-decorator';

import SearchOutline from '~/components/icons/SearchOutline.vue';
import ProfileRunSportTag from '~/components/partials/ProfileRunSportTag.vue';
import EventCategoryTag from '~/components/partials/EventCategoryTag.vue';

@Component({
  components: { SearchOutline, ProfileRunSportTag, EventCategoryTag },
})
export default class MenuGlobalSearchInput extends Vue {
  @Prop(String) selectedLoc: '';
  @Prop([Array, String]) selectedSports: [] | string;

  slicedSportsLeft: number = 0;

  /*
    This computed returns the amount of '+ X'sports to show based on window
    innerWidth of user device
  */
  get sportsToShow () {
    if (process.client) {
      if (window.innerWidth > 1440) {
        this.slicedSportsLeft = this.selectedSports.length - 2;
        return this.selectedSports.slice(0, 2);
      }
      this.slicedSportsLeft = this.selectedSports.length - 1;
      return this.selectedSports.slice(0, 1);
    }
    return this.selectedSports.slice(0, 2);
  }

  /*
    This method handle the tab to show of globalSearch modal based on where
    the user clicks in the template
  */
  handleSportDivClick () {
    this.$store.commit('SET_GLOBALSEARCH_DISPLAY', true);
    if (!this.selectedLoc) {
      this.$store.commit('explorer/SET_GLOBAL_SEARCH_TAB', 'destination');
    } else {
      this.$store.commit('explorer/SET_GLOBAL_SEARCH_TAB', 'sport');
    }
  }
}

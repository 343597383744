






















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
})
export default class ErrorLayout extends Vue {
  @Prop() error: any;

  /* Based on this.error object structure, we display translated messages */
  get description () {
    if (this.error.description) {
      return this.error.description;
    }
    switch (this.error.statusCode) {
      case 400: return this.$t('error.description.400');
      case 401: return this.$t('error.description.401');
      case 403: return this.$t('error.description.403');
      case 404: return this.$t('error.description.404');
      case 504: return this.$t('error.description.504');
      default: return this.$t('error.description.default');
    }
  }

  /* Returns the detail of this.error based on its object structure */
  get detail () {
    return this.error.response && this.error.response.data.detail;
  }
}













import { Vue, Component, Prop } from 'vue-property-decorator';

import GlobalSearchDestination from '~/components/lists/items/GlobalSearchDestination.vue';

@Component({
  components: { GlobalSearchDestination },
})
export default class GlobalSearchDestinationsList extends Vue {
  @Prop(Array) destinations: Types.DestinationCountry[];
}

// Taken from https://github.com/focus-trap/tabbable/blob/master/src/index.js
// slightly changed because of linting
// XXX: May be we should install the dependency instead?

const candidateSelectors = [
  'input',
  'select',
  'textarea',
  'a[href]',
  'button',
  '[tabindex]',
  'audio[controls]',
  'video[controls]',
  '[contenteditable]:not([contenteditable="false"])',
  'details>summary',
];
const candidateSelector = candidateSelectors.join(',');
const focusableCandidateSelector = candidateSelectors.concat('iframe').join(',');

const matches = typeof Element === 'undefined'
  ? function () { }
  : Element.prototype.matches ||
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;

/**
 * List tabbable nodes under a parent element.
 * @param {HTMLElement} el - parent element
 * @param {Object} options
 * @returns {HTMLElement[]}
 */
export function tabbable (el, { includeContainer = false } = {}) {
  const regularTabbables = [];
  const orderedTabbables = [];

  const candidates = getCandidates(el, includeContainer, isNodeMatchingSelectorTabbable);

  candidates.forEach(function (candidate, i) {
    const candidateTabindex = getTabindex(candidate);
    if (candidateTabindex === 0) {
      regularTabbables.push(candidate);
    } else {
      orderedTabbables.push({
        documentOrder: i,
        tabIndex: candidateTabindex,
        node: candidate,
      });
    }
  });

  return orderedTabbables
    .sort(sortOrderedTabbables)
    .map(a => a.node)
    .concat(regularTabbables);
}

export function focusable (el, { includeContainer = false } = {}) {
  return getCandidates(el, includeContainer, isNodeMatchingSelectorFocusable);
}

export function isTabbable (node) {
  if (!node) {
    throw new Error('No node provided');
  }
  if (matches.call(node, candidateSelector) === false) {
    return false;
  }
  return isNodeMatchingSelectorTabbable(node);
}

export function isFocusable (node) {
  if (!node) {
    throw new Error('No node provided');
  }
  if (matches.call(node, focusableCandidateSelector) === false) {
    return false;
  }
  return isNodeMatchingSelectorFocusable(node);
}

function isNodeMatchingSelectorFocusable (node) {
  if (node.disabled || isHiddenInput(node) || isHidden(node)) {
    return false;
  }
  return true;
}

function getCandidates (el, includeContainer, filter) {
  let candidates = Array.prototype.slice.apply(el.querySelectorAll(candidateSelector));
  if (includeContainer && matches.call(el, candidateSelector)) {
    candidates.unshift(el);
  }
  candidates = candidates.filter(filter);
  return candidates;
}

function isNodeMatchingSelectorTabbable (node) {
  return (
    isNodeMatchingSelectorFocusable(node) &&
    !isNonTabbableRadio(node) &&
    getTabindex(node) >= 0
  );
}

function getTabindex (node) {
  const tabindexAttr = parseInt(node.getAttribute('tabindex'), 10);

  if (!isNaN(tabindexAttr)) {
    return tabindexAttr;
  }

  // Browsers do not return `tabIndex` correctly for contentEditable nodes;
  // so if they don't have a tabindex attribute specifically set, assume it's 0.
  if (isContentEditable(node)) {
    return 0;
  }

  // in Chrome, <audio controls/> and <video controls/> elements get a default
  //  `tabIndex` of -1 when the 'tabindex' attribute isn't specified in the DOM,
  //  yet they are still part of the regular tab order; in FF, they get a default
  //  `tabIndex` of 0; since Chrome still puts those elements in the regular tab
  //  order, consider their tab index to be 0
  if (
    (node.nodeName === 'AUDIO' || node.nodeName === 'VIDEO') &&
    node.getAttribute('tabindex') === null
  ) {
    return 0;
  }

  return node.tabIndex;
}

function sortOrderedTabbables (a, b) {
  return a.tabIndex === b.tabIndex
    ? a.documentOrder - b.documentOrder
    : a.tabIndex - b.tabIndex;
}

function isContentEditable (node) {
  return node.contentEditable === 'true';
}

function isInput (node) {
  return node.tagName === 'INPUT';
}

function isHiddenInput (node) {
  return isInput(node) && node.type === 'hidden';
}

function isRadio (node) {
  return isInput(node) && node.type === 'radio';
}

function isNonTabbableRadio (node) {
  return isRadio(node) && !isTabbableRadio(node);
}

function getCheckedRadio (nodes, form) {
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].checked && nodes[i].form === form) {
      return nodes[i];
    }
  }
}

function isTabbableRadio (node) {
  if (!node.name) {
    return true;
  }
  const radioScope = node.form || node.ownerDocument;
  const radioSet = radioScope.querySelectorAll(`input[type="radio"][name="${node.name}"]`);
  const checked = getCheckedRadio(radioSet, node.form);
  return !checked || checked === node;
}

function isHidden (node) {
  // offsetParent being null will allow detecting cases where an element is invisible
  // or inside an invisible element, as long as the element does not use position: fixed.
  // For them, their visibility has to be checked directly as well.
  return node.offsetParent === null || getComputedStyle(node).visibility === 'hidden';
}































import { Vue, Component, Prop } from 'vue-property-decorator';

import MapOutline from '~/components/icons/MapOutline.vue';
import GlobalSearchFilteredProvider from '~/components/lists/items/GlobalSearchFilteredProvider.vue';

@Component({
  components: { MapOutline, GlobalSearchFilteredProvider },
})
export default class GlobalSearchProviders extends Vue {
  @Prop(String) searchText: '';
  @Prop(Array) providersResults: Types.Provider[];
  @Prop() suggested: Types.mappingOfSuggested[];

  isLoading: boolean = false;
}

import SunOutline from '~/components/icons/SunOutline.vue';
import CloudOutline from '~/components/icons/CloudOutline.vue';
import RainOutline from '~/components/icons/RainOutline.vue';
import SnowOutline from '~/components/icons/SnowOutline.vue';
import FogOutline from '~/components/icons/FogOutline.vue';
import GroomedSnowOutline from '~/components/icons/GroomedSnowOutline.vue';
import StormOutline from '~/components/icons/StormOutline.vue';
import DotsHorizontalSolid from '~/components/icons/DotsHorizontalSolid.vue';
import FrozenOutline from '~/components/icons/FrozenOutline.vue';

export const weathers = [
  { icon: SunOutline, id: 'SUNNY', title: 'runConstants.weather.sunny' },
  { icon: CloudOutline, id: 'CLOUDY', title: 'runConstants.weather.cloudy' },
  { icon: RainOutline, id: 'RAIN', title: 'runConstants.weather.rainy' },
  { icon: SnowOutline, id: 'SNOW', title: 'runConstants.weather.snowy' },
  { icon: FogOutline, id: 'FOGGY', title: 'runConstants.weather.foggy' },
  { icon: StormOutline, id: 'STORM', title: 'runConstants.weather.stormy' },
];

export const snowConditions = [
  { icon: SnowOutline, id: 'POWDER', title: 'runConstants.snowCondition.powder' },
  { icon: GroomedSnowOutline, id: 'PACKED', title: 'runConstants.snowCondition.packed' },
  { icon: FrozenOutline, id: 'ICY', title: 'runConstants.snowCondition.icy' },
  { icon: DotsHorizontalSolid, id: 'OTHER', title: 'runConstants.snowCondition.other' },
];

export const snowSports = [
  { iconName: 'ski', id: 'SKI', name: 'runConstants.snowSports.ski', imageName: 'ski', checked: false },
  { iconName: 'snowboard', id: 'SNOWBOARD', name: 'runConstants.snowSports.snowboard', imageName: 'snowboard', checked: false },
  { iconName: 'ski-touring', id: 'SKI_TOURING', name: 'runConstants.snowSports.skiTouring', imageName: 'skitouring', checked: false },
  { iconName: 'cross-country-skiing', id: 'CROSS_COUNTRY_SKIING', name: 'runConstants.snowSports.xcSki', imageName: 'crosscountryskiing', checked: false },
];

export const footSports = [
  { iconName: 'trail-running', id: 'TRAIL_RUNNING', name: 'runConstants.footSports.trail', imageName: 'trail', checked: false },
  { iconName: 'running', id: 'RUNNING', name: 'runConstants.footSports.running', imageName: 'running', checked: false },
  { iconName: 'nordic-walking', id: 'NORDIC_WALK', name: 'runConstants.footSports.nordic', imageName: 'nordicwalk', checked: false },
  { iconName: 'hiking', id: 'HIKING', name: 'runConstants.footSports.hiking', imageName: 'hiking', checked: false },
];

export const bikeSports = [
  { iconName: 'road-bike', id: 'ROAD_BIKE', name: 'runConstants.bikeSports.road', imageName: 'roadbike', checked: false },
  { iconName: 'mountain-bike', id: 'MOUNTAIN_BIKE', name: 'runConstants.bikeSports.mountain', imageName: 'mountainbike', checked: false },
  { iconName: 'gravel-bike', id: 'GRAVEL_BIKE', name: 'runConstants.bikeSports.gravel', imageName: 'gravelbike', checked: false },
];

export const otherSports = [
  { id: "OTHER", name: "runConstants.otherSports.other", checked: false },
];

export const sportsWithOptionalElevation = [
  { iconName: 'ski', id: 'SKI', name: 'runConstants.snowSports.ski', imageName: 'ski' },
  { iconName: 'snowboard', id: 'SNOWBOARD', name: 'runConstants.snowSports.snowboard', imageName: 'snowboard' },
  { iconName: 'mountain-bike', id: 'MOUNTAIN_BIKE', name: 'runConstants.bikeSports.mountain', imageName: 'mountainbike' },
];

export const sportsUniverses = [
  { id: 'WINTER', title: 'profile.myRuns.runEdit.sportsPicker.snowSportsLabel' },
  { id: 'FOOT', title: 'profile.myRuns.runEdit.sportsPicker.footSportsLabel' },
  { id: 'BIKE', title: 'profile.myRuns.runEdit.sportsPicker.bikeSportsLabel' },
  { id: 'OTHER', title: 'profile.myRuns.runEdit.sportsPicker.otherSportsLabel' },
];

export function sportHasOptionalElevation (sportId) {
  return sportsWithOptionalElevation.some(sport => sport.number === sportId);
}

export function getRunTag (sport) {
  const allSports = [...snowSports, ...footSports, ...bikeSports, otherSports];
  return allSports.find(item => item.id === sport);
}

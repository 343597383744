





























import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({
})
export default class ExperiencesPickerCard extends Vue {
  @Prop(Object) sport: Types.SportItem;
  @Prop([String, Object, Array, Number]) selected: string | {} | [] | number;
  @Prop(Boolean) singleSelect: false;
  @Prop(Boolean) singleCard: false;
  @Prop(Boolean) displayAsChips: false;
  @Prop(Boolean) isXs: false;
  @Prop(Boolean) customUnselectedStyle: false;

  /*
    This method updates the style of the selected element, then emits to the
    parent an object containing the action the user triggered, and the related
    object
  */
  @Emit('sport-selected')
  sportSelected () {
    this.sport.checked = !this.sport.checked;

    if (this.selected) {
      if (this.singleSelect) {
        return { id: this.sport.id, name: this.sport.name };
      } else {
        return { action: this.sport.checked ? 'ADD' : 'REMOVE', sport: this.sport };
      }
    }

    return { action: this.sport.checked ? 'ADD' : 'REMOVE', sport: this.sport };
  }

  /* This computed updates the style of the card based on props */
  get chipsAndSingleClassDisplay () {
    if (this.displayAsChips && this.singleCard) return 'lg:mx-0 py-2 px-4 mb-1.5';
    if (this.displayAsChips && this.isXs && !this.sport.imageName) return 'mr-1 lg:mr-2 py-0.5 px-2 mb-1.5';
    if (this.displayAsChips && this.isXs) return 'mx-1 lg:mx-2 py-1 px-2 mb-1.5';
    if (this.displayAsChips && !this.isXs) return 'mr-1 lg:mr-2 py-1.5 px-2 mb-1.5';
    if (this.singleCard) return 'lg:mx-2 font-bold flex-col px-2 py-4 w-11/12 h-12';
    return 'lg:mx-2 font-bold flex-col px-2 py-4 w-20 h-16 xs:w-24 xs:h-20';
  }

  /*
    This computed updates the status-style of the card based on its datas
    (checked or not)
  */
  get buttonClass () {
    const selectedClass = 'Btn-filled-gray border border-gray-default';
    const unselectedClass = this.customUnselectedStyle ? 'WiredChipsOnColoredBackground' : 'WiredChips';

    if (this.selected) {
      if (!this.singleSelect) {
        return this.sport.checked ? selectedClass : unselectedClass;
      }

      return (this.selected === this.sport.id) ? selectedClass : unselectedClass;
    }
    return this.sport.checked ? selectedClass : unselectedClass;
  }

  mounted () {
    // If the component is loaded once and has preselected datas, it may not be
    // reactive right away to populate template. So we trigger it on mounted
    setTimeout(() => {
      this.handlePreSelected();
    }, 500);
  }

  /*
    If this.selected contains value(s), then we will apply the process as if
    user was selecting a card in the template
  */
  handlePreSelected () {
    if (Array.isArray(this.selected)) {
      /* eslint-disable-next-line */
      return this.selected.map((select: any) => {
        if (select.id && (select.id === this.sport.id)) {
          this.sport.checked = false;
          return this.sportSelected();
        }
        if (select === this.sport.id) {
          this.sport.checked = false;
          return this.sportSelected();
        }
      });
    }
  }
}

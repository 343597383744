
































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';

import SideBarBaseModal from '~/components/modals/SideBarBaseModal.vue';
import UserSolid from '~/components/icons/UserSolid.vue';
import LanguageSwitcher from '~/components/partials/LanguageSwitcher.vue';

@Component({
  components: { SideBarBaseModal, UserSolid, LanguageSwitcher },
  computed: {
    ...mapState('user', {
      userInfos: 'me',
    }),
    ...mapState('lang', [
      'locale',
    ]),
  },
})
export default class ConfirmModal extends Vue {
  @Prop(String) title: '';
  @Prop(String) description: '{ No description }';
  @Prop(String) cancelLabel: '';
  @Prop(String) confirmLabel: '';
  @Prop(Boolean) userIsConnected: false;

  menuItems =[
    {
      name: this.$t('topMenu.menuItems.home'),
      link: '/',
    },
    {
      name: this.$t('topMenu.menuItems.explore'),
      link: '/explorer',
    },
    // {
    //   name: this.$t('topMenu.menuItems.blog'),
    //   link: '/blog',
    // },
  ];

  subMenuItems = [
    {
      name: this.$t('topMenu.menuItems.download'),
      link: '/onpiste-app',
    },
    {
      name: this.$t('footer.onPisteMenu.links.respect'),
      link: '/respect',
    },
    {
      name: this.$t('footer.onPisteMenu.links.handispot'),
      link: '/handispot',
    },
    {
      name: this.$t('topMenu.subMenuItems.contact'),
      link: '/contact',
    },
    // {
    //   name: this.$t('topMenu.subMenuItems.faq'),
    //   link: '/faq',
    // },
  ]

  socialsMenuItems = [
    {
      name: this.$t('footer.socialsMenu.facebook'),
      link: 'https://www.facebook.com/app.onpiste',
    },
    {
      name: this.$t('footer.socialsMenu.instagram'),
      link: 'https://www.instagram.com/onpiste.app/',
    },
  ]

  userInfos: Types.User;
  locale: string;

  /* Checks if user is connected */
  get checkIsConnected () {
    return this.$cookies.get('token');
  }
};

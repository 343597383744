


























































































































import { Vue, Component, Prop } from 'vue-property-decorator';

import Sparkles from '~/components/icons/Sparkles.vue';
import ChevronRightSolid from '~/components/icons/ChevronRightSolid.vue';

@Component({
  components: { Sparkles, ChevronRightSolid },
})
export default class GlobalSearchDestination extends Vue {
  @Prop(Object) country: Types.DestinationCountry;

  countryIsOpened: boolean = false;

  /*
    This computed is used to format this.country to remove regions inside a
    country that contains no name, so shouldn't be displayed
  */
  get countryFiltersRegions () {
    /* eslint array-callback-return: 0 */
    this.country.regions?.map((r: Types.DestinationRegion, i: number) => {
      if (!r.name.length) {
        this.country.regions?.push(this.country.regions.splice(i, 1)[0]);
      }
    });

    return this.country;
  }
}

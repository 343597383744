





































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

import { createFocusTrap } from '~/utils/dom';

import XSolid from '~/components/icons/XSolid.vue';

@Component({
  components: { XSolid },
})
export default class SideBarBaseModal extends Vue {
  @Prop(String) title: '';
  @Prop(Boolean) noEasyClose: false;

  onTabKey: any;

  // Emits a close event to the parent to close modal
  @Emit('modal-close')
  modalClose () {
  }

  /*
    Some keyboard events and click behaviors are handled in mounted to improve
    UX
  */
  mounted () {
    if (process.client) {
      this.onTabKey = createFocusTrap(this.$el);
      window.addEventListener('keydown', this.onTabKey);
      window.addEventListener('keyup', this.onEscape);
    }
  }

  /* Removes the events declared in mounted */
  beforeDestroy () {
    if (process.client) {
      window.removeEventListener('keydown', this.onTabKey);
      window.removeEventListener('keyup', this.onEscape);
    }
  }

  /*
    If user clicks on the background of the modal, it can be considered as an
    attempt to close the modal (except if this.noEasyClose declared)
  */
  easyClose () {
    if (this.noEasyClose) return;
    this.modalClose();
  }

  /* If user hits Escape key, it will close modal */
  onEscape (e: KeyboardEvent) {
    if (e.code === 'Escape' || e.keyCode === 27/* IE */) this.modalClose();
  }
}

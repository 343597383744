









import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class EventCategoryTag extends Vue {
  @Prop(String) label: '';
  @Prop(Boolean) displayAsSelectedStyle: false;
}

































import { Vue, Component, Prop } from 'vue-property-decorator';

import PenSolid from '~/components/icons/PenSolid.vue';
import UserSolid from '~/components/icons/UserSolid.vue';

@Component({
  components: { PenSolid, UserSolid },
})
export default class AvatarCard extends Vue {
  @Prop(String) label: '';
  @Prop(Boolean) displayEditIcon: false;
  @Prop(String) source: '';

  failedLoadingUrl: boolean = false;
}

export function paramSerializer (params) {
  const result = [];
  for (const name in params) {
    const value = params[name];

    if (value === undefined) {
      continue;
    } else if (Array.isArray(value)) {
      for (const v of value) {
        result.push([name, v]); // name: [a, b]  =>  ["name", a], ["name", b]
      }
    } else if (value && value.toJSON) {
      result.push([name, value.toJSON()]);
    } else {
      result.push([name, value]);
    }
  };
  return new URLSearchParams(result);
}
